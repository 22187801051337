#delivery-categories {
	width: 100%;
    height: 100%;
}

	#delivery-categories .delivery-categories-container {
		width: calc(100% - 100px);
		height: 100%;
		display: -webkit-flex;
		display: flex;
		overflow: auto;
		box-sizing: border-box;
		padding: 30px;
	}
	
		.delivery-categories-container .content-blocks {		
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
			width: 100%;
			height: 100%;
			display: flex;
		}

		/* .content-block {
			width: 50%;
			height: 100%;
			box-sizing: border-box;
			padding: 35px;
			overflow: auto;
		} */

		.content-block.light {
			background: #fff;
		}

		.content-block.dark {
			background: #EDEDED;
		}

			.content-block-intro {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
				.content-block-intro .add{
					margin-top: 15px;
				}

				.content-block-title {
					display: flex;
					align-items: center;
					color: #292C36;
					font-size: 20px;
					font-weight: normal;
				}

					.content-block-icon {
						display: inline-block;
						width: 22px;
						height: 18px;
						margin: 0px 15px 0px 0px;
					}

					.content-block-icon.structure {
						mask: url("../../../assets/images/structure_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/structure_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						background: #F75942;
					}

					.content-block-icon.metadata {
						mask: url("../../../assets/images/cateogry_metadata_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/cateogry_metadata_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						height: 24px !important;
						background: #F75942;
					}

			.content-block-main {
				margin: 20px 0px 0px 0px;
				height: calc(100% - 60px);
				display: flex;
				flex-direction: column;
			}
				.content-block-main__timeslot-form{
					display: flex;
					gap:20px;

				}
				/* .content-block-main .add{
					text-transform: uppercase;
				} */

				.content-block-main .input-field--labeled{
					padding: 5px;
					display: flex;
					align-items: flex-start;
					justify-content: space-evenly;
				}
				.delivery-table{
					width: 100%;
					border-collapse: collapse;
					border-spacing: 0;
				} 
					.delivery-table-body{

					}
						.delivery-table-body tr{

						}
						.delivery-table-body tr td{

						}
							.delivery-table-body tr td button{
								/* background-color: red !important; */
								color: black !important;
								padding: 0px 15px !important;
								border-radius: 20px;
								height: 39px;
								line-height: 39px;
								border: 0px;
								margin: 0px 0px 0 30px !important;
								cursor: pointer;
								font-size: inherit;
								font-weight: bold;
								display: inline-block;
								box-sizing: border-box;
								transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
								box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
								overflow: hidden;
							
							}

				.table-content {
					margin-top: 40px;
					display: flex;
					width: 100%;
				}

					.table-content .delivery-table{
						/* display: flex; */
						width: 100%;
						/* background-color: coral; */
					}
					.table-content .delivery-table .delivery-table-head tr th{
						color: #292C36;
						font-size: 13px;
						font-weight: 600;
						text-align: left;
						/* text-align: center; */
						box-sizing: border-box;
						padding: 15px;
						position: -webkit-sticky;
						position: sticky;
						top: -1px;
						background: #ffffff;
						border-bottom: 1px solid #DEDEDE;
						/* border-top: 1px solid #DEDEDE; */
						z-index: 1;
						text-transform: uppercase;
						font-weight: bold;
					}

					.table-content .delivery-table .delivery-table-body{
						padding: 31px 0px;
						background: transparent;
						border-bottom: 1px solid #E6E6E6;
						cursor: pointer;
						position: relative;
						overflow: scroll;
						height: 100%;
						transition: all 0.2s ease-in-out;
					}

						.delivery-table-body td{
							padding: 15px;
							/* text-align: center; */
						}
						/* .delivery-table-body td button{
							transform-origin: right;
							transform: scale(0, 1);
							transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
						} */

				.content-block-main__schedule-dropdown{
					margin-top: 30px;
					font-size: 18px;
				}

					.double-date-picker{
						display: flex;
						gap:45px;
						margin: 30px 0;
					}

					.single-date--picker{
						margin: 30px 0;
					}

					.radio-form {
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						margin: 30px 0;
						gap:5px;

					}
						.radio--labeled-day{
							background: transparent;
							border: 1px solid #707070;
							width: 17px;
							height: 17px;
							border-radius: 50%;
							display: flex;
							position: relative;
							cursor: pointer;
							margin: 0px;
							align-items: center;
							justify-content: center;
						}
				.root-category-disclaimer {
					width: 100%;
					height: 100%;
				}

					.if-empty {
						width: 100%;
						height: 100%;
						position: relative;
					}

				#delivery-categories .categories-main-list {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
					width: 100%;
					line-height: unset;
					height: unset;
					margin: 0px;
					padding: 0px;
				}

					#delivery-categories .categories-main-list.drag-over-gap-bottom, 
					#delivery-categories .categories-main-list.drag-over-gap-top,
					#delivery-categories .categories-sub-list.drag-over-gap-top,
					#delivery-categories .categories-sub-list.drag-over-gap-bottom
					{
						border-bottom: 2px dashed #f75942;
					}

				#delivery-categories .ant-tree li .ant-tree-node-content-wrapper {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					width: calc(100% - 18px);
					border-radius: 3px;
					background: transparent;
					margin: 0px;
					padding: 0px;
					position: relative;
					transition: background 0.2s ease-in-out;
					line-height: unset;
					height: unset;
					border: 0px;
				}

					#delivery-categories .ant-tree li .categories-sub-list .ant-tree-node-content-wrapper {
						width: 100%;
					}

					#delivery-categories .ant-tree li .ant-tree-node-content-wrapper .ant-tree-title {
						display: inline-block;
						width: 100%;
						border-bottom: 2px solid transparent;
						box-sizing: border-box;
						transition: all 0.2s ease-in-out;
					}

					#delivery-categories .main-category {
						display: flex;
						width: 100%;
						padding: 10px;
						align-items: center;
						justify-content: space-between;
						background: transparent;
						transition: background 0.2s ease-in-out;
						border-radius: 3px;
					}

					#delivery-categories .ant-tree .main-category:hover {
						background: #F7F7F7;
					}
					
					#delivery-categories .main-category:hover h2:before {
						background: #F75942;
						cursor: move;
					}
					
					#delivery-categories .main-category:hover .category-options {
						visibility: visible;
						opacity: 1;
						transform: scale(1);
					}

					#delivery-categories .main-category h2 {
						color: #868686;
						font-size: 14px;
						font-weight: normal;
						text-transform: capitalize;
						margin: 0px;
					}

						#delivery-categories .main-category h2:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
							-webkit-mask-size: 100%;
							mask-size: 100%;
							width: 16px;
							height: 10px;
							background: #EAEAEA;
							transition: background 0.2s ease-in-out;
							margin: 0px 10px 0px 0px;	
						}

						#delivery-categories .categories-main-list ul {
							box-sizing: border-box;
							width: 100%;
							padding: 0px 0px 0px 46px;
						}

							#delivery-categories .categories-sub-list {
								padding: 0px;
								box-sizing: border-box;
								margin: 0px;
								border-bottom: 2px dashed transparent;
							}

								#delivery-categories .categories-sub-list span.ant-tree-switcher {
									display: none !important;
								}
								
								#delivery-categories .categories-sub-list .main-category {
									display: flex;
									align-items: center;
									justify-content: space-between;
									padding: 10px;
									background: transparent;
								}

								#delivery-categories .category-tree li {
									display: flex;
									align-items: center;
									flex-wrap: wrap;
									padding: 0px;
								}

									.ant-tree-child-tree.ant-tree-child-tree-open {
										width: 100%;
										box-sizing: border-box;
										padding: 0px 0px 0px 45px;
									}

								#delivery-categories .categories-sub-list:last-child {
									margin: 0px 0px 0px 0px;
								}

								#delivery-categories .categories-main-list .categories-sub-list li:hover {
									background: #F7F7F7;
								}
			
								#delivery-categories .categories-main-list .categories-sub-list li:hover h2:before {
									background: #F75942;
									cursor: move;
								}
			
								#delivery-categories .categories-main-list:first-child:hover {
									background: transparent;
								}
								
								#delivery-categories .categories-main-list .categories-sub-list:hover .category-options {
									visibility: visible;
									opacity: 1;
									transform: scale(1);
								}
				#delivery-categories .ant-tree li span.ant-tree-switcher {
					width: 18px;
					height: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.category-options {
					display: flex;
					align-items: center;
					/* margin: 0px 15px 0px 0px; */
					visibility: hidden;
					opacity: 0;
					transform: scale(0.8);
					transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
				}

					#delivery-categories .icon-button {
						display: inline-block;
						margin: 0px;
						padding: 0px;
						border: 0px;
						cursor: pointer;
						margin: 0px 7px;
						background: transparent;
						overflow: visible;
					}

						.icon-button:before {
							background: #606060;
							width: 12px;
							transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
						}

						.icon-button:hover:before {
							background: #333333;
							transform: scale(1.2);
							width: 12px;
						}

						.icon-button.new:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
							-webkit-mask-size: 100%;
							mask-size: 100%;
							height: 12px;
						}

						.icon-button.edit:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/edit_category_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/edit_category_icon.svg") no-repeat center center;
							-webkit-mask-size: 97%;
							mask-size: 97%;
							height: 13px;
							width: 15px;
						}

						.icon-button.delete:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/delete_category_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/delete_category_icon.svg") no-repeat center center;
							-webkit-mask-size: 99%;
							mask-size: 99%;
							height: 13px;
							width: 11px;
						}

						.icon-button.edit {
							margin-right: 5px;
						}

			.content-block.dark .content-block-main {
				margin: 35px 0px 0px 0px;
				position: relative;
			}

				.content-block.dark .content-block-main .empty-state-container {
					width: 100%;
					height: calc(100% - 50px);
					padding: 0px 40px;
				}

					.content-block.dark .content-block-main .empty-state-container .centered-title {
						font-size: 18px; 
					}

					.content-block.dark .content-block-main .empty-state-container .centered-icon {
						width: 56px;
						height: 56px;
					}

			/* #delivery-categories .delivery-categories-container .content-block-main .input-holder {
				margin-bottom: 20px;
			} */

			#delivery-categories .action-buttons {
				position: fixed;
				bottom: 0;
				right: 0;
				box-sizing: border-box;
				padding: 25px;
			}
		
				#delivery-categories .action-buttons button {
					width: 135px;
					margin: 10px 0px;
				}

				#delivery-categories .button-holder {
					display: flex;
					align-items: center;
					margin: 30px 0px 0px 0px;
				}

					.button-holder button {
						width: 115px;
						margin: 0px 5px 0px 0px;
					}

					.rounded-button.primary.ghost.cancel-button {
						color: #5F6A78;
					}
		
				.save-button:before {
					content: "";
					display: inline-block;
					width: 13px;
					height: 13px;
					mask: url("../../../assets/images/save_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/save_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #fff;
					transform: scale(1);
					margin: 0px 15px 0px 0px;
					will-change: transform;
					transition: all 0.2s ease-in-out;
				}
		
				.cancel-button:before {
					content: "";
					display: inline-block;
					width: 16px;
					height: 16px;
					mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #fff;
					transform: scale(1);
					margin: 0px 15px 0px 0px;
					will-change: transform;
					transition: all 0.2s ease-in-out;
				}

					.ghost-icon-button.new-category:before {
						content: "";
						display: inline-block;
						mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						height: 12px;
						width: 12px;
						background: #f75942;
						margin: 0px 10px 0px 0px;
					}
	.root-category-disclaimer {
		width: 100%;
		height: 100%;
	}

		.if-empty {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: space-between;
		}

		.root-category-disclaimer .empty-state-container {
			box-sizing: border-box;
			padding: 0px 40px;
			height: calc(100% - 60px);
		}

		.empty-categories h2 {
    		color: #5F6A78;
    		font-size: 16px;
    		font-weight: lighter;
		}

		.category-tree {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			align-content: space-between;
		}

			.ant-tree.ant-tree-icon-hide {
				width: 100%;
				height: calc(100% - 65px);
				overflow: auto;
			}

	#delivery-categories .input-holder.search {
		margin-top: 20px;
		margin-bottom: 0px !important;
	}

	.items-group {
		box-sizing: border-box;
		background: #f5f5f5;
		border: 1px solid #00000012;
		box-shadow: 0px 0px 15px 1px #00000008;
		margin: 20px 0px 0px 0px;
		max-height: calc(100% - 41px);
		overflow: auto;
		height: auto;
	}
		
		.item-master {
			box-sizing: border-box;
			padding: 10px;
			border-bottom: 1px solid #0000000d;
			margin: 0px 0px 0px 0px;
			opacity: 1;
			cursor: move;
			user-select: none;
			transform: scale(1);
			transform-origin: center;
			transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
		}

			.item-scale-on-drag {
				display: -webkit-flex;
				display: flex;
				align-items: center;
				pointer-events: none;
				transform: scale(1);
				transform-origin: center;
				transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
			}

			.item-master .drop-target {
				transform: scale(0.85);
				opacity: 0.5;
			}

			.item-master:hover {
				opacity: 0.7;
			}

			.items-group .item-master:last-child {
				margin-bottom: 0px;
				border-bottom: none;
			}

			.image {
				width: 40px;
				height: 40px;
				overflow: hidden;
				margin: 0px;
				border: 1px solid #282c362b;
				pointer-events: none;
			}

				.image img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					pointer-events: none;
				}

			.item-info {
				width: calc(100% - 40px);
				box-sizing: border-box;
				padding: 0px 0px 0px 10px;
				pointer-events: none
			}

				.item-name {
					font-size: 16px;
					font-weight: bold;
					margin: 0px 0px 0px 0px;
					color: #282c36;
					pointer-events: none
				}

				.item-description {
					font-size: 12px;
					color: #757575;
					margin: 0px;
					pointer-events: none
				}

		.count {
			display: flex;
			align-items: center;
			pointer-events: none;
		}

			.count-src {
				display: inline-block;
				background: #f75942;
				color: #fff;
				font-weight: bold;
				font-size: 12px;
				line-height: 19px;
				width: 19px;
				height: 19px;
				text-align: center;
				border-radius: 50%;
				margin: 0px 10px 0px 0px;
				pointer-events: none;
			}

			.drag-src {
				display: inline-block;
				mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
				-webkit-mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 18px;
				width: 21px;
				background: #282c3645;
				transform: rotate(90deg);
				cursor: move;
				pointer-events: none;
			}

#delivery-categories #tabs {
	height: calc(100% - 45px);
}

	#delivery-categories #tabs .tabs-container {
		height: 100%;
	}

		#delivery-categories #tabs .tabs-container .tab-content {
			height: calc(100% - 55px);
		}

		#delivery-categories .content-block-main.items-block {
			margin: 0px 0px 0px 0px;
			height: 100%;
		}

	.front-back-buttons {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: space-between;
		pointer-events: none;
		position: relative;
		bottom: 1px;
	}

		.icon-button.to-front {
			display: inline-block;
			mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			width: 11px;
			height: 11px;
			background: #282c36 !important;
			-webkit-mask-position-y: 1px;
			margin: 0px !important;
			cursor: pointer;
			pointer-events: auto;
		}

		.icon-button.to-back {
			display: inline-block;
			mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			width: 11px;
			height: 11px;
			background: #282c36 !important;
			-webkit-mask-position-y: 0px;
			transform: rotate(180deg);
			margin: 0px !important;
			cursor: pointer;
			pointer-events: auto;
		}

		.front-back-buttons .deactivate {
			pointer-events: none;
		}

/* ************************************************************************** */
/* *************************** RADIO PICKER GROUP *************************** */
/* ************************************************************************** */

.radio-picker-group {
	width: 100%;
	border-radius: 5px;
	border: 1px solid silver;
	display: flex;
	background-color: #fff;
}

	.radio-picker-group label {
		flex-grow: 1;
		text-align: center;
		cursor: pointer;
		justify-content: space-evenly;
		display: flex;
		border: 1px solid silver;
		/* padding: 0 4px; */
		/* gap: 3px; */
	}

	.radio-picker-group label:hover {
		background-color: silver;
		
	}

		.radio-picker-group label input {
			
		}

		.radio-picker-group label span {
			display: block;
			width: 100%;
		}

		.radio-picker-group label input:checked+span {
			background: linear-gradient(45deg, #f77f42 0%,#f75942 100%);
			color: white;
			font-weight: bold;
		}
/* ************************************************************************** */
/* *************************** REPEATED CHECKBOX CSS *************************** */
/* ************************************************************************** */

.check-label{
	margin: 18px 0 0 8px;
}

.checkbox-grid{
	display: flex !important;
	justify-content: flex-end !important;
	align-items: flex-end !important;
}
.repeated{
	margin-top: 18px !important;
}

/* ************************************************************************** */
/* *************************** SCHEDULE CONTAINER CSS *************************** */
/* ************************************************************************** */


.dropdown-list{
	border: 2px solid black !important;
}
.schedule-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	gap: 20px;
}

/* ************************************************************************** */
/* *************************** SCHEDULE TABLE ADDITIONAL CSS *************************** */
/* ************************************************************************** */

.schedule-table thead  {
	font-size: 13px !important;
	border-bottom: 1px solid #aca5a5 !important;
	padding: 15px !important;
	gap: 10px !important;
}

.schedule-table thead tr th {
	padding: 15px !important;
}

.schedule-table tbody {
	border-bottom: 1px solid #aca5a5 !important;
}

.schedule-table tbody td {
	padding: 15px !important;
	margin-left: 15px !important;
}
