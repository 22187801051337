html, body {
	margin: 0px;
	width: 100%;
	height: 100%;
	min-height: 100%;
	font-family: 'Open Sans', sans-serif !important;
	background: #FAFAFA !important;
}

html body h1, h2, h3, h4, h5, h6, p {
	margin: 0px;
	outline: none;
}

html body ul, li {
	margin: 0px;
	padding: 0px;
	list-style: none;
	outline: none;
}

html body input, select, button, textarea {
	outline: none;
	-webkit-appearance: none;
}

html body a {
	color: inherit;
	text-decoration: none;
}

html body button {
	overflow: hidden;
	will-change: transform;
}

/* 
	To find UI Segments of the App USE: '#'
	[ 
		#INPUTS,
		#BUTTONS,
		#SELECT_BOX,
		#BADGES_TAGS,
		#RICH_TEXT_EDITOR
		#ROOT_UI
	]
*/


/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#INPUTS
*/

.input-holder {
	width: 100%;
	margin: 37px 0px 0px 0px;
	position: relative;
}

.input-holder:first-child {
	margin: 0px 0px 0px 0px;
}

	.input-field--labeled {
		width: 100%;
		box-sizing: border-box;
		margin: 0px;
		padding: 3px 0px 5px 0px;
		border: 0px;
		font-size: 14px;
		color: #606060;
		border-bottom: 1px solid #DEDEDE;
		background: transparent;
	}

		.input-field--labeled[type="number"]:not(:placeholder-shown) + .field-label {
			font-size: 9px;
			color: #B1B1B1;
			font-weight: normal;
			transform: translate(0px, -55px);
		}

		#app .input-holder.error-active .input-field--labeled[type="number"]:valid:placeholder-shown + .field-label {
			font-size: 14px;
			color: #606060;
			transform: translate(0px, -40px);
		}

		.input-border {
			display: inline-block;
			border: 0px;
			margin: 0px;
			height: 1px;
			width: 100%;
			background: #F75942;
			position: absolute;
			bottom: 0;
			left: 0;
			transform-origin: center;
			transform: scale(0, 1);
			opacity: 0;
			transition: all 0.2s ease-in-out;
		}

		.input-field--labeled:focus ~ .input-border {
			opacity: 1;
			transform: scale(1, 1);
		}

		.input-field--labeled:focus + .field-label, 
		.input-field--labeled:focus:disabled + .field-label, 
		.input-field--labeled:valid + .field-label,
		.input-field--labeled:disabled + .field-label, 
		.field-label.dirty-label {
			font-size: 9px;
			color: #B1B1B1;
			font-weight: normal;
			transform: translate(0px, -55px);
		}

		.input-field--labeled:disabled {
			color: #B1B1B1;
			border-color: #B1B1B1 !important;
			cursor: not-allowed;
		}

	.field-label {
		transition: all 0.2s ease-in-out;
		display: inline-block;
		width: 100%;
		transform: translate(0px, -40px);
		font-size: 14px;
		color: #606060;
		position: absolute;
		top: 44px;
		left: 0;
		cursor: text;
		z-index: 1;
	}

	.rounded-data-holder.field-label--dirty .field-label {
		font-size: 9px;
		color: #B1B1B1;
		font-weight: normal;
		transform: translate(0px, -11px);
	}

	#product-details .rounded-data-holder.field-label--dirty.badges.light.categories-ant .data-line .ant-select {
		margin: 5px 0px 0px 0px;
	}

	.rounded-data-holder.field-label--dirty  {
		margin: 7px 0px 0px 0px;
	}

.text-field--labeled {
    display: inline-block;
    width: 100%;
    min-height: 24px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px 0px 6px 0px;
    border: 0px;
    font-size: 14px;
    line-height: 20px;
    color: #606060;
    border-bottom: 1px solid #DEDEDE;
    background: transparent;
    max-height: 65px;
    resize: none;
}

	.text-field-label {
		transition: all 0.2s ease-in-out;
		display: inline-block;
		width: 100%;
		transform: translate(0px, 0px);
		font-size: 14px;
		color: #606060;
		cursor: text;
		position: absolute;
		left: 0;
		top: 0;
	}

	.textarea-holder .input-border {
		bottom: 5px;
	}

	.text-field--labeled:focus ~ .input-border {
		opacity: 1;
		transform: scale(1, 1);
	}

	.text-field--labeled:focus + .text-field-label, .text-field--labeled:valid + .text-field-label {
		font-size: 9px;
		color: #B1B1B1;
		font-weight: normal;
		transform: translate(0px, -14px);
	}

.input-holder.date-holder {
	cursor: pointer;
}

	.input-holder.date-holder input {
		width: 100%;
		box-sizing: border-box;
		margin: 0px;
		padding: 3px 0px 5px 17px;
		border: 0px;
		font-size: 14px;
		color: #606060;
		border-bottom: 1px solid #DEDEDE;
		background: transparent;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}

		.input-holder.date-holder input::placeholder {
			color: #606060;
		}

		.input-holder.date-holder .field-label {
			box-sizing: border-box;
			padding: 0px 0px 0px 17px;
			pointer-events: none;
		}

		.field-label.active-date-label {
			font-size: 9px;
			color: #B1B1B1;
			font-weight: normal;
			transform: translate(0px, -56px);
		}

		.input-holder.date-holder input:focus, .input-holder.date-holder input:valid {
			border-bottom: 1px solid #F75942 !important;
		} 

	.input-holder.date-holder:after {
		content: "";
		display: inline-block;
		mask: url("./assets/images/select-chevron.svg") no-repeat center center;
		-webkit-mask: url("./assets/images/select-chevron.svg") no-repeat center center;
		-webkit-mask-size: 100%;
		mask-size: 100%;
		width: 7px;
		height: 5px;
		background: #AAAAAA;
		position: absolute;
		right: 5px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.input-holder.date-holder:before {
		content: "";
		display: inline-block;
		mask: url("./assets/images/calendar_icon.svg") no-repeat center center;
		-webkit-mask: url("./assets/images/calendar_icon.svg") no-repeat center center;
		-webkit-mask-size: 100%;
		mask-size: 100%;
		width: 11px;
		height: 13px;
		background: #AAAAAA;
		position: absolute;
		left: 0;
		top: -4px;
		bottom: 0;
		margin: auto;
	}

#app .input-holder.error-active .field-label {
	/* color: #e84118; */
}

#app .input-holder.error-active .input-field--labeled:focus + .field-label + .input-error {
	opacity: 0;
}

#app .input-holder.error-active .input-field--labeled:focus + .field-label, #app .input-holder.error-active .input-field--labeled:valid + .field-label {
	color: #B1B1B1;
}

#app .input-holder.error-active .input-field--labeled:valid + .field-label + .input-error {
	opacity: 0;
}

#app .input-holder.error-active .input-field--labeled:focus ~ .input-border {
    opacity: 1;
    -webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	background: #e84118 !important;
}

#app .input-holder.error-active .input-field--labeled:valid ~ .input-border {
    opacity: 1;
    -webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	background: #5F6A78;
}

#app .input-holder.error-active .input-field--labeled ~ .input-border {
    opacity: 1;
    -webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	background: #e84118;
}

#app .textarea-holder.input-holder.error-active .text-field-label {
	/* color: #e84118; */
}

#app .textarea-holder.input-holder.error-active .text-field--labeled:focus + .text-field-label + .input-error {
	opacity: 0;
}

#app .textarea-holder.input-holder.error-active .text-field--labeled:focus + .text-field-label, #app .input-holder.error-active .text-field--labeled:valid + .text-field-label {
	color: #B1B1B1;
}

#app .textarea-holder.input-holder.error-active .text-field--labeled:valid + .text-field-label + .input-error {
	opacity: 0;
}

#app .textarea-holder.input-holder.error-active .text-field--labeled:focus ~ .input-border {
    opacity: 1;
    -webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	background: #e84118 !important;
}

#app .textarea-holder.input-holder.error-active .text-field--labeled:valid ~ .input-border {
    opacity: 1;
    -webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	background: #5F6A78;
}

#app .textarea-holder.input-holder.error-active .text-field--labeled ~ .input-border {
    opacity: 1;
    -webkit-transform: scale(1, 1);
	transform: scale(1, 1);
	background: #e84118;
}

#app .input-error {
    font-size: 10px;
    position: absolute;
    white-space: nowrap;
    top: -11px;
    color: #e84118;
	font-weight: bold;
	display: none;
	opacity: 1;
	transition: all 0.2s ease-in-out;
}


#app .input-holder.error-active .input-error,
#app .rounded-data-holder.badges.light.categories-ant.error-active .input-error,
#app p.rounded-data-holder.tags.light.error-active .input-error {
	display: block;
}

#app .rounded-data-holder.badges.light.categories-ant .input-error {
	top: 4px;
	z-index: 1;
}

#app p.textarea-holder.input-holder.full.error-active .input-error {
	top: -14px;
	z-index: 1;
}

#app .input-holder.date-holder input:disabled {
	color: #B1B1B1;
    border-color: #B1B1B1 !important;
    cursor: not-allowed;
}

	/* BADGE ERROR [Empty, Invalid states] */
		#app p.rounded-data-holder.tags.light.error-active .data-title {
			/* color: #e84118; */
		}

		#app p.rounded-data-holder.tags.light.error-active .data-line {
			border-color: #e84118;
		}

/* 
	#SWITCH
*/

.switch-holder {
    display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0px 0px 0px 0px;
}

	.switch-holder .field-label {
		position: static;
		transform: none;
		width: auto;
		margin: 0px 10px 0px 0px;
	}

		.switch-holder .ant-switch-checked {
			background-color: #f75941;
		}

.switch-grid {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.switch-grid.linear {
	justify-content: flex-start;
}

	.switch-grid.linear .switch-holder {
		margin: 0px 20px 20px 0px;
	}

.switch-holder.disabled .field-label {
	color: #B1B1B1;
	cursor: not-allowed;
	user-select: none;
}


/* 
	#CHECKBOX
*/

.checkbox-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

	.checkbox-grid .checkbox-holder {
		width: auto;
	}

		#form-details .checkbox-grid .checkbox-holder {
			margin: 0px 0px 0px 0px;
		}

		#form-details .checkbox-grid .checkbox-holder:first-child {
			margin: unset;
		}

.input-holder.checkbox-holder.disabled {
	cursor: not-allowed;
}

.input-holder.checkbox-holder.disabled .checkbox-label {
	color: #B1B1B1;
	cursor: not-allowed;
	user-select: none;
}

.checkbox--labeled:disabled {
	border-color: #B1B1B1;
}

.checkbox--labeled:disabled:checked:after {
	background: #fabdb4;
}

.checkbox--labeled:disabled:checked:hover:after {
	background: #fabdb4 !important;
}

/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#BUTTONS
*/

button {
	white-space: nowrap;
}

.rounded-button {
	border-radius: 20px;
	height: 39px;
	line-height: 39px;
	border: 0px;
	margin: 0px;
	padding: 0px 15px;
	cursor: pointer;
	font-size: inherit;
	font-weight: bold;
	display: inline-block;
	box-sizing: border-box;
	transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.rounded-button.primary.orange.gradient {
	color: #fff;
	background: #f77f42;
	background: -moz-linear-gradient(45deg, #f77f42 0%, #f75942 100%);
	background: -webkit-linear-gradient(45deg, #f77f42 0%,#f75942 100%);
	background: linear-gradient(45deg, #f77f42 0%,#f75942 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f77f42', endColorstr='#f75942',GradientType=1 );
	position: relative;
	opacity: 1;
}

.rounded-button.primary.gradient.blue {
	color: #fff;
	background: #42b1f7;
	background: -moz-linear-gradient(-45deg, #77ced1 0%, #42b1f7 100%);
	background: -webkit-linear-gradient(-45deg, #77ced1 0%, #42b1f7 100%);
	background: linear-gradient(135deg, #77ced1 0%, #42b1f7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42b1f7', endColorstr='#77ced1',GradientType=1 );
	position: relative;
}

.rounded-button.primary.gradient.pink {
	color: #fff;
	background: #b977d1;
	background: -moz-linear-gradient(-45deg, #b977d1 0%, #f74288 100%);
	background: -webkit-linear-gradient(-45deg, #b977d1 0%, #f74288 100%);
	background: linear-gradient(135deg, #b977d1 0%, #f74288 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42b1f7', endColorstr='#77ced1',GradientType=1 );
	position: relative;
}

.rounded-button.primary.gradient.darkblue {
	color: #fff;
	background: #4057a3;
	background: -moz-linear-gradient(-45deg, #4057a3 0%, #22252e 100%);
	background: -webkit-linear-gradient(-45deg, #4057a3 0%,#22252e 100%);
	background: linear-gradient(135deg, #4057a3 0%,#22252e 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4057a3', endColorstr='#22252e',GradientType=1 );
	position: relative;
}

	.rounded-button.primary.gradient .button-text {
		position: relative;
		z-index: 1;
		-webkit-font-smoothing: antialiased;
		line-height: 11px;
	}

	.rounded-button.primary.gradient:before {
		position: relative;
		z-index: 1;
		transform: rotate(0deg) scale(1);
		transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
	}

	.rounded-button.primary.gradient.orange:after {
		/* content: ""; */
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #f77f42;
		background: -moz-linear-gradient(45deg, #f75942 0%, #f77f42 100%);
		background: -webkit-linear-gradient(45deg, #f75942 0%,#f77f42 100%);
		background: linear-gradient(45deg, #f75942 0%,#f77f42 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f75942', endColorstr='#f77f42',GradientType=1 );
		transform-origin: right;
		transform: scale(0, 1);
		transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
	}

	.rounded-button.primary.gradient.blue:after {
		/* content: ""; */
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #42b1f7;
		background: -moz-linear-gradient(-45deg, #42b1f7 0%, #77ced1 100%);
		background: -webkit-linear-gradient(-45deg, #42b1f7 0%, #77ced1 100%);
		background: linear-gradient(135deg, #42b1f7 0%, #77ced1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42b1f7', endColorstr='#77ced1',GradientType=1 );
		transform-origin: right;
		transform: scale(0, 1);
		transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
	}

	.rounded-button.primary.gradient.pink:after {
		/* content: ""; */
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #b977d1;
		background: -moz-linear-gradient(-45deg, #f74288 0%, #b977d1 100%);
		background: -webkit-linear-gradient(-45deg, #f74288 0%, #b977d1 100%);
		background: linear-gradient(135deg, #f74288 0%, #b977d1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42b1f7', endColorstr='#77ced1',GradientType=1 );
		transform-origin: right;
		transform: scale(0, 1);
		transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
	}

	.rounded-button.primary.gradient.darkblue:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #4057a3;
		background: -moz-linear-gradient(-45deg, #22252e 0%, #4057a3 100%);
		background: -webkit-linear-gradient(-45deg, #22252e 0%,#4057a3 100%);
		background: linear-gradient(135deg, #22252e 0%,#4057a3 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#22252e', endColorstr='#4057a3',GradientType=1 );
		transform-origin: right;
		transform: scale(0, 1);
		transition: all 0.3s cubic-bezier(1, -0.15, 0.64, 0.88);
	}

	.rounded-button.primary.gradient:hover {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
		opacity: 0.85;
	}

	.rounded-button.primary.gradient:active {
		box-shadow: none;
		opacity: 1;
	}

	.rounded-button.primary.gradient:hover:after {
		visibility: visible;
		opacity: 1;
		transform: scale(1, 1);
	}

	.rounded-button.primary.gradient:hover:before {
		transform: rotate(361deg) scale(1.15);
	}

.circle-button {
	width: 39px;
	height: 39px;
	text-align: center;
	line-height: 39px;
	border-radius: 50%;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
	cursor: pointer;
	transform: scale(1);
	transition: all 0.2s ease-in-out;
}

.circle-button.light {
	background: #fff;
	border: 1px solid #DEDEDE;	
}

.primary-icon {
	font-size: 12px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}

.square-button {
	width: 32px;
	height: 32px;
	text-align: center;
	line-height: 36px;
	border-radius: 3px;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
	cursor: pointer;
}

.square-button.light.icon.settings-icon {
    background: #FAFAFA;
    border: 1px solid #C9C9C9;
}

.circle-button.light {
	background: #fff;
	border: 1px solid #DEDEDE;	
}

.circle-button.dark {
	background: #141519;
}

	.circle-button.dark:hover {
		opacity: 0.85;
		transform: scale(1.05);
	}

.icon-button {
	border: 0px;
	margin: 0px;
	padding: 0px;
	background: transparent;
	cursor: pointer;
	display: inline-block;
}

	.icon-button:before {
		content: "";
		display: inline-block;
	}

.rounded-button.ghost {
	width: auto;
	box-shadow: none;
	-webkit-font-smoothing: antialiased;
	line-height: 11px;
	text-transform: uppercase;
	font-size: 12px;
	color: #3c5092;
	background: transparent;
	transition: color 0.2s ease-in-out;
}

	.rounded-button.ghost:hover {
		color: #21242e;
	}

	.rounded-button.ghost:before {
		content: none;
	}

.ghost-icon-button {
	background: transparent;
	border: 0px;
	height: 39px;
    line-height: 39px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
	color: #5F6A78;
	border-radius: 50px;
	box-shadow: none;
}

#app .two-block-content .action-buttons button {
	width: 115px;
	margin: 10px 0px;
}

#app .rounded-button:disabled {
	cursor: not-allowed;
	background: #e0e0e0;
    box-shadow: none;
    color: #b7b7b7;
}

	#app .rounded-button:disabled:hover {
		opacity: 1;
	}

	#app .rounded-button.primary-icon:disabled:before {
		background: #b7b7b7;
	}

	#app .rounded-button.primary-icon:disabled:hover:after {
		visibility: hidden;
		opacity: 0;
		transform: scale(0, 0);
	}

	#app .rounded-button.primary-icon:disabled:hover:before {
		transform: rotate(0deg) scale(1);
	}

/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#SELECT_BOX
*/

.select-holder {
	position: relative;
	width: 100%;
	cursor: pointer;
	/* border-bottom: 1px solid #5F6A78; */
	border-bottom: 1px solid #DEDEDE;
}

	.select-value {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		box-sizing: border-box;
		margin: 0px;
		padding: 0px 0px 4px 0px;
		border: 0px;
		font-size: 14px;
		color: #606060;
		/* border-bottom: 1px solid #DEDEDE; */
		background: transparent;
		position: relative;
		/* line-height: 14px; */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

		.select-value-src {
			display: inline-block;
			width: calc(100% - 20px);
			height: 17px;
			line-height: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.select-value-container:after {
			content: "";
			display: inline-block;
			mask: url("./assets/images/select-chevron.svg") no-repeat center center;
			-webkit-mask: url("./assets/images/select-chevron.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			width: 7px;
			height: 5px;
			background: #AAAAAA;
			position: absolute;
			right: 5px;
			top: 1px;
			bottom: 0;
			margin: auto;
		}

	.select-dropdown {
		position: absolute;
		z-index: 2;
		top: 31px;
		width: 230px;
		left: -1px;
		background: #fff;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.11);
		border-radius: 3px;
		box-sizing: border-box;
		padding: 10px 0px;
		border: 1px solid #00000014;
		max-height: 250px;
		overflow: auto;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
	}

		#app .delete-select-value {
			margin: 0px 0px 0px 0px;
			color: #fff;
			width: 13px;
			height: 13px;
			background: #aaaaaa;
			border-radius: 50%;
			font-size: 12px;
			position: relative;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}

			#app .delete-select-value:before {
				content: "";
				display: inline-block;
				mask: url("./assets/images/x_icon.svg") no-repeat center center;
				-webkit-mask: url("./assets/images/x_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
			    height: 5px;
				width: 9px;
				padding: 0px;
				box-sizing: border-box;
				border: 0px;
				cursor: pointer;
				background: #ffffff;
				transition: background 0.2s ease-in-out;
			}

		.select-dropdown span {
			display: block;
			width: 100%;
			box-sizing: border-box;
			padding: 10px 15px;
			font-size: 14px;
			color: #606060;
			background: transparent;
			text-transform: capitalize;
			cursor: pointer;
			word-break: break-word;
			transition: background 0.2s ease-in-out;
		}

			.select-dropdown span:hover {
				background: whitesmoke;
			}

			.select-dropdown span:last-child {
				border: 0px;
			}

	.select-value-container {
		width: 100%;
		display: inline-block;
		position: relative;
	}

		.select-value-container .select-label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin: 0px;
			padding: 4px 0px;
			border: 0px;
			font-size: 14px;
			color: #606060;
			background: transparent;
			position: relative;
		}

		.select-value-container.dirty .select-label {
			position: absolute;
			font-size: 9px;
			color: #B1B1B1;
			font-weight: normal;
			transform: translate(0px, -15px);
		}

.select-holder.opened .select-dropdown {
	opacity: 1;
	visibility: visible;
	overflow-y: scroll;
}

.button-dropdown-holder.opened .select-dropdown {
	opacity: 1;
	visibility: visible;
}

.select-value-container.dirty {
    box-sizing: border-box;
    padding: 3px 0px 0px 0px;
}

	.select-value-container.dirty .select-value {
		padding: 5px 0px 4px 0px;
}

.input-holder.select-holder.disabled {
    border-color: #B1B1B1;
    cursor: not-allowed !important;
}

	.input-holder.select-holder.disabled .delete-select-value {
		pointer-events: none;
}

	.input-holder.select-holder.disabled .select-value-container .select-label {
		color: #B1B1B1;
	}

	.input-holder.select-holder.disabled .select-value-container .select-value {
		color: #B1B1B1;
	}
	
	.input-holder.select-holder.disabled .delete-select-value {
		opacity: 0.5;
	}

	.input-holder.select-holder.disabled .select-value-container:after {
		background: #B1B1B1;
		content: none;
	}

	.rounded-data-holder.light .single-data-item.disabled-tags {
		color: #b1b1b1;
		cursor: not-allowed;
	}

	.rounded-data-holder.light .single-data-item.disabled-tags .remove-button {
		background: #b1b1b1;
		cursor: not-allowed;
	}

		.rounded-data-holder.light .single-data-item.disabled-tags:hover {
			border-color: #E6E6E6;
		}

			.rounded-data-holder.light .single-data-item.disabled-tags:hover .remove-button{
				background: #b1b1b1;
			}



			.rounded-data-holder.light.disabled-tags .add-new-item {
				color: #b1b1b1;
				border-color: #b1b1b1;
				cursor: not-allowed;
				pointer-events: none;
			}

			.rounded-data-holder.light.disabled-tags .single-data-item {
				color: #b1b1b1;
				border-color: #b1b1b1;
				cursor: not-allowed;
				pointer-events: none;
			}

				.rounded-data-holder.light.disabled-tags .add-new-item:before {
					background: #b1b1b1;
				}
		
			.rounded-data-holder.light.disabled-tags .remove-button {
				background: #b1b1b1;
				cursor: not-allowed;
			}
		
				.rounded-data-holder.light.disabled-tags .single-data-item:hover {
					border-color: #b1b1b1;
				}
		
					.rounded-data-holder.light.disabled-tags .single-data-item:hover .remove-button{
						background: #b1b1b1;
					}

	span.disabled-dropdown-button {
		background: #eaeaea;
		color: #c1c1c1;
		border-color: transparent;
		cursor: not-allowed;
	}

	.rounded-data-holder span.disabled-dropdown-button:hover {
		background: #eaeaea;
		color: #c1c1c1;
		border-color: transparent;
	}

	.rounded-data-holder span.disabled-dropdown-button:before {
		background: #c1c1c1;
	}

	.rounded-data-holder span.disabled-dropdown-button:hover:before {
		background: #c1c1c1;
	}

	#app p.input-holder.select-holder.error-active {
		border-color: #e84118;
	}


/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#BADGES_TAGS
*/

.rounded-data-holder {
	width: 100%;
	max-width: 100%;
	position: relative;
}

	.rounded-data-holder .data-title {
		font-size: 9px;
		color: #B1B1B1;
		font-weight: normal;
		margin: 0px 0px 5px 0px;
		display: inline-block;
	}

	.rounded-data-holder .data-line {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		width: 100%;
		box-sizing: border-box;
		margin: 0px;
		padding: 0px 0px 0px 0px;
		border: 0px;
		font-size: 14px;
		color: #606060;
		/* border-bottom: 1px solid #606060; */
		border-bottom: 1px solid #DEDEDE;
		background: transparent;
	}

		.rounded-data-holder .data-line .ant-input-sm {
			padding: 1px 7px;
			height: 28px;
			border-color: #F75942;
			margin: 0px 0px 9px 0px;
		}

		.single-data-item, 
		#app .ant-select-selection--multiple .ant-select-selection__choice {
			height: auto !important;
			font-weight: normal;
			font-size: 14px;
			border-radius: 3px;
			box-sizing: border-box;
			padding: 3px 5px;
			display: flex;
			align-items: center;
			text-transform: lowercase;
			cursor: pointer;
			margin: 0px 8px 8px 0px;
			background: transparent;
		}

		#app .ant-select-selection--multiple .ant-select-selection__choice {
			flex-direction: row-reverse;
		}

			#app .ant-select-selection--multiple .ant-select-selection__choice__content {
				line-height: 22px;
			}

			#app .ant-select-selection--multiple {
				min-height: unset;
			}

		.rounded-data-holder.light .single-data-item {
			border: 1px solid #E6E6E6;
			color: #606060;
		}

			.rounded-data-holder.light .remove-button {
				background: #606060;
			}

			#app .ant-select-selection--multiple .ant-select-selection__choice__remove:before {
				content: none;
			}

			#app .ant-select-selection--multiple .ant-select-selection__choice__remove {
				content: "";
				position: static;
				display: inline-block;
				mask: url("./assets/images/x_icon.svg") no-repeat center center;
				-webkit-mask: url("./assets/images/x_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 9px;
				width: 12px;
				margin: 2px 0px 0px 3px;
				padding: 0px;
				box-sizing: border-box;
				border: 0px;
				cursor: pointer;
				background: #606060;
				transition: background 0.2s ease-in-out;
			}

			#app .ant-select-selection--multiple {
				padding-bottom: 6px;
			}

			.ant-select-tree {
				padding: 7px !important; 
			}

				.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
					background-color: #f759421c !important;
				}

				.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
					background-color: #f75942 !important;
					color: #fff !important;
				}
		
		.rounded-data-holder.color.orange .single-data-item {
			border: 0px;
			background: #F75942;
			color: #fff;
		}

			.rounded-data-holder.color.orange .remove-button {
				background: #fff;
			}

			.remove-button {
				display: inline-block;
				mask: url("./assets/images/x_icon.svg") no-repeat center center;
				-webkit-mask: url("./assets/images/x_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 9px;
				width: 12px;
				margin: 2px 0px 0px 3px;
				padding: 0px;
				box-sizing: border-box;
				border: 0px;
				cursor: pointer;
				transition: background 0.2s ease-in-out;
			}

		.add-new-item {
			color: #f75942;
			font-weight: bold;
			font-size: 11px;
			border: 1px solid #f75942;
			border-radius: 3px;
			box-sizing: border-box;
			padding: 0px 5px;
			height: 25px;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			cursor: pointer;
			margin: 0px 0px 10px 0px;
			position: relative;
    		top: 2px;
			transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
		}

			.add-new-item:before {
				content: "";
				display: inline-block;
				mask: url("./assets/images/plus_icon.svg") no-repeat center center;
				-webkit-mask: url("./assets/images/plus_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 8px;
				width: 8px;
				background: #f75942;
				margin: 0px 3px 0px 0px;
				transition: background 0.2s ease-in-out;
			}

.rounded-data-holder.light .single-data-item {
	transition: border 0.2s ease-in-out;
}

	.rounded-data-holder.light .single-data-item:hover {
		border: 1px solid #f75942;
	}

	.rounded-data-holder.light .single-data-item:hover .remove-button {
		background: #f75942;
	}

.rounded-data-holder.color .single-data-item {
	transition: background 0.2s ease-in-out;
}

	.rounded-data-holder.color.orange .single-data-item:hover {
		background: #e2361d;
	}

.rounded-data-holder .add-new-item:hover {
	border: 1px solid  #e2361d;
	color: #e2361d;
}

	.rounded-data-holder .add-new-item:hover:before{
		background: #e2361d;
	}

#app p.rounded-data-holder.badges.light.categories-ant.error-active .data-line {
	border-color: #e84118;
}

	#app p.rounded-data-holder.badges.light.categories-ant.error-active .ant-select-search__field__placeholder {
		/* color: #e84118; */
	}	

	#app .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
		line-height: 46px;
		height: 46px;
	}


/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#TABLES
*/

.bc-table-wrap {
	width: 100%;
	box-sizing: border-box;
	padding: 20px 28px 0px 28px;
	height: calc(100% - 74px);
	position: relative;
}

.bc-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

	.bc-table-head tr th {
		/* color: #292C36;
		font-size: 16px;
		font-weight: 600;
		text-align: left;
		box-sizing: border-box;
		padding: 15px 0px;
		position: sticky;
		top: 0px;
		background: #fafafa;
		border-bottom: 1px solid #DEDEDE;
		z-index: 1; */
		color: #292C36;
		font-size: 13px;
		font-weight: 600;
		text-align: left;
		box-sizing: border-box;
		padding: 15px 0px;
		position: -webkit-sticky;
		position: sticky;
		top: -1px;
		background: #ffffff;
		border-bottom: 1px solid #DEDEDE;
		border-top: 1px solid #DEDEDE;
		z-index: 1;
		text-transform: uppercase;
		font-weight: bold;
	}

	.bc-table-head tr .bc-table-data-centered {
		text-align: center;
	}

	.bc-table-body .bc-table-data-centered {
		text-align: center;
	}

	/* .bc-table-head tr th:first-child {
		padding: 15px 0px 15px 10px;
	} */

		/* .bc-table-body tr.bc-table-row td:first-child {
			box-sizing: border-box;
			padding-left: 10px;
		} */

		.bc-table-body tr.bc-table-row table tr td:first-child {
			padding: 0px;
		}

		.bc-table-head tr th.pusher {
			color: #FAFAFA;
			pointer-events: none;
		}

		.bc-table-body .bc-table-row {
			padding: 31px 0px;
			background: transparent;
			border-bottom: 1px solid #E6E6E6;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			transition: all 0.2s ease-in-out;
		}

			.bc-table-body .bc-table-row:hover {
				background: #fff;
				box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
			}

		.bc-table-body .bc-table-data {
			/* padding: 31px 0px; */
			padding: 15px 0px;
			position: relative;
		}

		.bc-button-table-data {
			text-align: center;
		}

		.table-classic-data {
			font-size: 14px;
			font-weight: normal;
			color: #606060;
			margin: 0px;
			width: 100px;
		}

			.product-title {
				font-size: 14px;
				font-weight: 600;
				color: #606060;
				/* max-width: 100px; */
				max-width: unset;
				line-height: 20px;
				margin: 0px;
				/* white-space: nowrap; */
			}


/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#CARDS
*/


.bc-cards-container {
	padding: 35px 28px 0px 28px;
    display: flex;
	align-items: flex-start;
	align-content: flex-start;
    flex-wrap: wrap;
}

	.bc-card {
		/* width: 200px;
		background: #fff;
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
		margin: 0px 20px 20px 0px;
		cursor: pointer;
		border-radius: 3px; */

		width: 200px;
		background: #fff;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
		margin: 0px 20px 20px 0px;
		cursor: pointer;
		border-radius: 3px;
		border: 1px solid #00000012;
	}

		.bc-card-content {
			box-sizing: border-box;
			padding: 20px;
			min-height: 97px;
			height: 97px;
		}

		.bc-card-description {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 9px;
			box-sizing: border-box;
			color: #fff;
			margin: 0px 3px 0px 0px;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			display: inline-block;
			line-height: 16px;
			text-align: center;
		}

			.bc-card .bc-card-description.vendor {
				background: #3498db;
			}

			.bc-card .bc-card-description.manufacturer {
				background: #2ecc71;
				
			}

			.bc-card-title {
				font-size: 16px;
				font-weight: 600;
				margin: 0px 0px 2px 0px;
				color: #5F6A78;
				line-height: 20px;
			}

		.bc-card-actions {
			background: #3498db;
			box-sizing: border-box;
			padding: 10px 20px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			background: #EDEDED;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}



/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/


/* 
	#RICH_TEXT_EDITOR
*/


.bc-rte {
    width: 100%;
	margin: 30px 0px 0px 0px;
	height: auto;
}

	.quill .ql-editor {
		min-height: 250px;
		width: 100%;
	}

	.field-label-inputless {
		font-size: 16px;
		margin: 0px 0px 10px 0px;
	}

	.bc-rte.quill-disabled {
		opacity: 0.6;
		cursor: not-allowed;
		pointer-events: none;
	}

		.bc-rte.quill-disabled .ql-toolbar.ql-snow, .bc-rte.quill-disabled .ql-toolbar.ql-snow + .ql-container.ql-snow {
			pointer-events: none;
		}

			.bc-rte.quill-disabled .ql-editor {
				pointer-events: none;
				user-select: none;
			}




/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------*/

/* 
	#ROOT_UI
*/

#root, 
#app,
.main-wrap {
	width: 100%;
	height: 100%;
}

#app {
	display: flex;
	flex-wrap: wrap;
}

	.main-content {
		width: calc(100% - 262px);
		height: 100%;
	}

		.main-content-scrollable {
			width: 100%;
			height: calc(100% - 74px);
			overflow: auto;
		}

		.bc-image-holder {
			display: inline-block;
			border: 2px solid #272c3629;
			width: auto;
			height: auto;
			overflow: hidden;
		}

		.bc-image-holder.large {
			width: 60px;
			height: 60px;
		}

			.bc-image-holder.rounded {
				border-radius: 50%;
			}

			.bc-image-holder img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.pusher {
				color: #FAFAFA;
				pointer-events: none;
			}

			/* #products-table .input-holder.half input,
			#products-table .input-holder.full input,
			#products-table .select-holder.half .select-value,
			#products-table .textarea-holder textarea,
			#products-table .select-holder.full input,
			.select-value, .select-holder {
				border-color: #DEDEDE;
			}

			#users-table .content-block-main .input-holder.half input, #users-table .content-block-main .input-holder.full input, #users-table .content-block-main .select-holder.half .select-value, #users-table .content-block-main .textarea-holder textarea, #users-table .content-block-main .select-holder.full input, #users-table .content-block-main .input-holder.date-holder input, #users-table .select-value {
				border-bottom: 1px solid #DEDEDE;
			}

			#vendors .content-block-main .input-holder.half input, #vendors .content-block-main .input-holder.full input, #vendors .content-block-main .select-holder.half .select-value, #vendors .content-block-main .textarea-holder textarea, #vendors .content-block-main .select-holder.full input, #vendors .content-block-main .input-holder.date-holder input, #vendors .select-value {
				border-bottom: 1px solid #DEDEDE;
			} */

			/* .content-block-main .input-holder.half input,
			.content-block-main .input-holder.full input,
			.content-block-main .select-holder.half .select-value,
			.content-block-main .textarea-holder textarea,
			.content-block-main .select-holder.full input,
			.content-block-main .input-holder.date-holder input,
			.content-block-main .input-holder.medium input,
			.content-block-main .input-holder.tiny input,
			.select-value {
				border-color: #5F6A78;
			} */
	
			.content-block.light {
				background: #fff;
			}
	
			.content-block.dark {
				background: #EDEDED;
			}
	
				.content-block-intro {
					display: flex;
					align-items: center;
				}

				.input-grid,
				.input-grid-mapped  {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-content: flex-start;
				}

					.content-block-main .input-holder.half,
					.content-block-main .select-holder.half  {
						width: 48%;
						max-width: 48%;
						margin: 0px 0px 0px 0px;
					}

					.content-block-main .input-holder.medium {
						width: 68%;
						max-width: 68%;
						margin: 0px 0px 0px 0px;
					}

					.content-block-main .input-holder.tiny {
						width: 28%;
						max-width: 28%;
						margin: 0px 0px 0px 0px;
					}

	.bc-details-container {
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
		overflow: unset;
		box-sizing: border-box;
		padding: 30px;
	}

	.full-block {
		width: 100%;
		display: flex;
	}

		.full-block.two, .full-block.one {
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
		}

		.full-block.long .content-block {
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
		}

			.full-block.long .content-block {
				width: 70%;
			}

			.content-block {
				width: 50%;
				height: 100%;
				box-sizing: border-box;
				padding: 35px;
			}  

			.content-block.light {
				background: #fff;
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}

			.content-block.dark {
				background: #EDEDED;
			}

				.content-block-intro {
					display: flex;
					align-items: center;
				}

					.content-block-title {
						display: flex;
						align-items: center;
						color: #292C36;
						font-size: 20px;
						font-weight: normal;
						white-space: nowrap;
						margin: 0px 25px 0px 0px;
					}

				.content-block-main {
					margin: 50px 0px 0px 0px;
				}

	.radio-buttons {
		width: 48%;
		position: relative;
    	bottom: 10px;
	}

		.radio-buttons h2 {
			margin: 0px 0px 0px 0px;
			font-size: 14px;
			color: #606060;
		}

		.input-holder.radio-holder {
			display: flex;
			width: auto;
			align-items: center;
			float: left;
			margin: 5px 10px 0px 0px;
		}

			.radio--labeled {
				background: transparent;
				border: 1px solid #707070;
				width: 17px;
				height: 17px;
				border-radius: 50%;
				display: flex;
				position: relative;
				cursor: pointer;
				margin: 0px;
				align-items: center;
				justify-content: center;
			}

				.radio--labeled:disabled {
					opacity: 0.3;
				}

				.radio--labeled:disabled:hover:after {
					opacity: 0;
				}
										
				.radio--labeled:checked:after {
					content: "";
					background: #FA583A;
					width: 11px;
					height: 11px;
					border-radius: 50%;
					position: absolute;
				}

				.radio--labeled:hover:after {
					content: "";
					background: #FA583A;
					width: 11px;
					height: 11px;
					border-radius: 50%;
					position: absolute;
					opacity: 0.3;
				}

				.radio--labeled:checked:hover:after {
					content: "";
					background: #FA583A;
					width: 11px;
					height: 11px;
					border-radius: 50%;
					position: absolute;
					opacity: 1;
				}

				#app .filter-buttons li.active span {
					color: #000000;
				}

				#app .filter-buttons li span {
					font-size: 16px;
					font-weight: normal;
					color: #a7a7a7;
				}