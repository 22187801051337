#companies {
	width: 100%;
	height: 100%;
}

	#companies .bc-card-actions button {
		margin: 0px 5px 0px 0px;
		overflow: visible;
		width: 18px;
		height: 13px;
		display: flex;
		align-items: center;
	}

	#companies .delete-button:before {
		margin: 0px;
		height: 13px;
		-webkit-mask-size: 95%;
		mask-size: 95%;
	}

	#companies .input-holder {
		max-width: 250px;
	}

	.companies-loader {
		width: 100%;
		height: 100%;
	}

		#companies #loader {
			position: static;
			width: 100%;
			height: calc(100% - 73px);
		}

		.bc-cards-container {
			width: 100%;
			height: 100%;
		}