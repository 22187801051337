.input-holder.full.form-permalink {
    margin-bottom: 25px;
}

.bc-table-head-data.form-name {
    width: 170px;
}

.bc-table-head-data.form-email {
    width: 200px;
}

.bc-table-head-data.form-title {
    width: 220px;
}

.bc-table-head-data.form-files {
    width: 100px;
}

.bc-table-head-data.form-topic {
    width: 150px;
}

.bc-table-head-data.form-phone {
    width: 150px;
}

.minimal-table-head tr th, .minimal-table-head tr th:first-child {
    padding: 10px 10px 10px 0;
}

.minimal-table-body tr td, .minimal-table-body tr td:first-child {
	padding: 6px 10px 6px 0px;
    vertical-align: top;
}

.full-block.long.form-data {
    align-items: flex-end;
}

.full-block.long.form-data .action-buttons {
    position: static;
    box-sizing: border-box;
    padding: 0px 20px;
}

.full-block.one.messages {
    margin: 30px 0px 15px 0px;
}

.full-block.one.messages .content-block .content-block-table {
    padding-top: 0px;
}

