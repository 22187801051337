#sidebar {
	width: 262px;
	height: 100%;
	background: #22252E;
}

.sidebar-container {
	width: 100%;
	height: 100%;
}

	.logo {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

		#sidebar .logo .logo-text {
			width: calc(100% - 85px);
			box-sizing: border-box;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
		}

			#sidebar .logo .logo-symbol {
				width: 65px;
				height: 73px;
				background: #292C36;
				display: flex;
				align-items: center;
				justify-content: center;
			}

				.logo .logo-icon {
					display: flex;
					align-items: center;
					justify-content: center;
				}

					.logo .logo-icon img {
						width: 46px;
					}

		#sidebar .logo .logo-text h2 {
			font-size: 18px;
			text-transform: uppercase;
			margin: 0px;
		}

			.logo .logo-text h2 span.b-orange {
				font-weight: normal;
				color: #F75942;
			}

			.logo .logo-text h2 span.c-light {
				font-weight: bold;
				color: #fff;
			}

			#sidebar .logo .b-desc {
				font-weight: lighter;
				font-size: 13px;
				color: #5F6A78;
			}

	.sidebar-content {
		width: 100%;
		height: calc(100% - 75px);
		display: flex;
	}

		.icons {
			width: 65px;
			height: 100%;
			background: #282c36;
			box-sizing: border-box;
			padding: 10px 0px 0px 0px;
			overflow: auto;
		}

			.single-row {
				width: 100%;
				height: 58px;
				background: #282c36;
				border-left: 3px solid #272c36;
				box-sizing: border-box;
			}

				.single-row a {
					display: flex;
					width: 100%;
					height: 100%;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;
					transition: background 0.2s ease-in-out;
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
				}

					.single-row a:hover {
						background: #22252E;
					}

						.single-row a:hover .sidebar-icon {
							background: #F75942;
						}

						.single-row a:hover .sidebar-menu-item {
							color: #fff;
						}

					.single-row a.active {
						background: #22252E;
					}

						.single-row a.active .sidebar-icon {
							background: #F75942;
						}

						.single-row a.active .sidebar-menu-item {
							color: #fff;
						}

					.single-row a .sidebar-icon {
						display: inline-block;
						width: 28px;
						background: #5F6A78;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						text-indent: -9999px;
						transition: background 0.2s ease-in-out;
					}

					.sidebar-icon.users-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/users_icon_sidebar.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/users_icon_sidebar.svg") no-repeat center center;
					}
					.sidebar-icon.eshop-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/ehsop_icon_sidebar.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/ehsop_icon_sidebar.svg") no-repeat center center;
					}
					.sidebar-icon.stats-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/stats_icon_sidebar.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/stats_icon_sidebar.svg") no-repeat center center;
					}
					.sidebar-icon.pages-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/pages_icon_sidebar.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/pages_icon_sidebar.svg") no-repeat center center;
						-webkit-mask-size: 79% !important;
						mask-size: 79%;
					}
					.sidebar-icon.payments-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/payments_icon_sidebar.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/payments_icon_sidebar.svg") no-repeat center center;
					}
					.sidebar-icon.invoices-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/invoices_icon_sidebar.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/invoices_icon_sidebar.svg") no-repeat center center;
					}
					.sidebar-icon.dashboard-sidebar-icon {
						height: 26px;
						mask: url("../../../../assets/images/chart-line.svg") no-repeat center center;
						-webkit-mask: url("../../../../assets/images/chart-line.svg") no-repeat center center;
					}

					.sidebar-menu-item {
						color: #5F6A78;
						font-size: 9px;
						font-weight: bold;
						text-transform: lowercase;
						display: block;
						width: 100%;
						text-align: center;
						margin: 7px 0px 0px 0px;
						transition: color 0.2s ease-in-out;
						line-height: 5px;
					}

		.icon-pairings {
			width: calc(100% - 65px);
			box-sizing: border-box;
			padding: 10px 0px 0px 0px;
			overflow: auto;
		}

			.single-icon-pairing {
				width: 100%;
				border-left: 15px solid #22252E;
				border-right: 15px solid #22252E;
				box-sizing: border-box;
				margin: 0px 0px 5px 0px;
			}

				.single-icon-pairing a {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					padding: 5px 10px;
					border-radius: 3px;
					box-sizing: border-box;
					transition: background 0.2s ease-in-out;
				}

					.single-icon-pairing a:hover {
						background: #141519;
					}

						.single-icon-pairing a:hover .pairing-data {
							color: #fff;
						}

						.single-icon-pairing a:hover .pairing-count {
							color: #F75942;
						}

					.single-icon-pairing a .pairing-data {
						color: #5F6A78;
						font-size: 14px;
						text-transform: capitalize;
						font-weight: normal;
						transition: color 0.2s ease-in-out;
					}

					.single-icon-pairing a .pairing-count {
						color: #fff;
						font-size: 14px;
						text-transform: capitalize;
						font-weight: normal;
						transition: color 0.2s ease-in-out;
					}

					.single-icon-pairing a.active {
						background: #141519;
					}

						.single-icon-pairing a.active .pairing-data {
							color: #fff;
							font-weight: bold;
						}

						.single-icon-pairing a.active .pairing-count {
							color: #F75942;
						}