.bc-chart-legend {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin: 20px 0px 0px 0px;
    box-sizing: border-box;
    padding: 0px 0px 0px 24px;
}

	.single-legend-data {
		display: flex;
		align-items: center;
		margin: 0px 20px 0px 0px;
	}

		.legend-color {
			width: 13px;
			height: 13px;
			border-radius: 4px;
		}

		.legend-info {
			margin: 0px 0px 0px 10px;
		}

			.legend-info h2 {
				font-size: 14px;
				margin: 0px;
				color: #292c36;
			}