#product-details {
	width: calc(100% - 135px);
    height: 100%;
}

	/* #product-details .product-details-container {
		width: 100%;
		height: 100%;
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
		overflow: unset;
		box-sizing: border-box;
		padding: 30px;
	}

		.full-block {
			width: 100%;
			display: flex;
		}

		.content-block {
			width: 50%;
			box-sizing: border-box;
			padding: 35px;
		} */

		#product-details .content-block {
			overflow: visible;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}

		#product-details .content-block .rounded-data-holder .data-line .ant-input-sm {
			margin: 0px 0px 7px 0px;
		}

		.image-item.basic.cover {
			border: 2px solid #272c36;
			background: #dadada4f;
		}

		/* .content-block.light {
			background: #fff;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}

		.content-block.dark {
			background: #EDEDED;
		}

			.content-block-intro {
				display: flex;
				align-items: center;
			}

				.content-block-title {
					display: flex;
					align-items: center;
					color: #292C36;
					font-size: 20px;
					font-weight: normal;
					white-space: nowrap;
					margin: 0px 25px 0px 0px;
				}

					.content-block-icon {
						display: inline-block;
						width: 22px;
						height: 18px;
						margin: 0px 15px 0px 0px;
					} */

					.content-block-icon.general-information {
						mask: url("../../../assets/images/general_information_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/general_information_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						background: #F75942;
					}

					.content-block-icon.images {
						mask: url("../../../assets/images/images_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/images_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						background: #F75942;
					}

					.content-block-icon.metadata {
						mask: url("../../../assets/images/cateogry_metadata_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/cateogry_metadata_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						height: 24px !important;
						background: #F75942;
					}

					.content-block-icon.price {
						mask: url("../../../assets/images/price_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/price_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						height: 24px !important;
						background: #F75942;
					}

			.content-block-main {
				margin: 50px 0px 0px 0px;
			}

				.button-dropdown-holder {
					position: relative;
					margin-bottom: 0px;
				}

					.button-dropdown-holder .select-dropdown {
						width: 200px;
					}

			#product-details .content-block .content-block-main .input-holder {
				margin: 0px 0px 25px 0px;
			}

			#product-details .product-details-container .content-block .content-block-main .textarea-holder {
				margin: 8px 0px 15px 0px;
			}

			.content-block-main .input-holder.full {
				width: 100%;
				max-width: 100%;
			}

				.input-grid {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-content: flex-start;
				}

					.rounded-data-holder {
						margin: 0px 0px 15px 0px;
					}

				.select-input-grid {
					margin: 25px 0px 0px 0px;
				}

					.select-input-grid .input-holder {
						margin: 0px 0px 25px 0px;
					}

			.content-block-intro.images {
				margin: 10px 0px 0px 0px;
			}

				.image-add-block {
					width: 100%;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin: 35px 0px 0px 0px;
				}

					.image-item {
						width: calc(100% / 3 - 20px);
						height: 95px;
						border: 1px;
						border-color: #DEDEDE;
						border-style: solid;
						border-width: 1px;
						display: flex;
						align-items: center;
						justify-content: center;
						box-sizing: border-box;
						margin: 0px 20px 20px 0px;
						cursor: pointer;
						position: relative;
						transition: border-color 0.2s ease-in-out;
					}

						.image-item:last-child {
							margin: 0px 0px 20px 0px;
						}

						.image-item.basic:hover {
							border-color: #F75942;
						}

						.image-item.basic:hover .remove-image {
							visibility: visible;
							opacity: 1;
							transform: scale(1);
						}

					.image-item.add-button {
						border-style: dashed;
						cursor: pointer;
						border-width: 2px;
					}

						.image-item.add-button:hover {
							border-color: #F75942;
						}

						.button-centered {
							display: flex;
							max-width: 80%;
							max-height: 75%;
							overflow: hidden;
							height: 100%;
							align-items: center;
							justify-content: center;
						}

							.button-centered img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}

							.image-item.add-button .button-centered span {
								display: inline-block;
								color: #606060;
								font-weight: normal;
								font-size: 12px;
							}	

								.image-item.add-button .button-centered span:before {
									content: "";
									display: block;
									mask: url("../../../assets/images/plus_icon.svg") no-repeat center center;
									-webkit-mask: url("../../../assets/images/plus_icon.svg") no-repeat center center;
									-webkit-mask-size: 100%;
									mask-size: 100%;
									width: 10px;
									height: 10px;
									background: #F75942;
									margin: 5px auto;
								}						

						.remove-image {
							position: absolute;
							right: -10px;
							top: -10px;
							width: 20px;
							height: 20px;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #F75942;
							margin: 0px;
							padding: 0px;
							border: 0px;
							cursor: pointer;
							visibility: hidden;
							opacity: 0;
							transform: scale(0.9);
							transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
						}

							.remove-image:before {
								content: "";
								background: #fff;
								display: inline-block;
								mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
								-webkit-mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
								-webkit-mask-size: 100%;
								mask-size: 100%;
								width: 12px;
								height: 12px;
							}

			.content-block.dark.metadata .content-block-intro {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

				.content-block.dark.metadata .content-block-intro .input-holder.select-holder.half{
					margin: 0px;
				}

				.input-block-titled {
					width: 100%;
					margin: 20px 0px 0px 0px;
					box-sizing: border-box;
					padding: 0px 0px 20px 0px;
					border-bottom: 1px solid #D9D9D9;
				}

				.input-block-titled.one.select .input-block-title {
					margin: 0px 0px 8px 0px;
				}

				.input-block-titled:first-child {
					margin: 20px 0px 0px 0px;
				}

				#product-details .content-block .input-block-titled.two .input-holder {
					margin-bottom: 0px;
				}

				#product-details .content-block .input-block-titled.one.select .rounded-data-holder {
					margin-bottom: 0px;
				}

					.input-block-title {
						color: #292C36;
						font-size: 16px;
						font-weight: normal;
						margin: 0px 0px 25px 0px;
					}

				#product-details .content-block .input-block-titled.select.four .input-holder.select-holder.tiny {
					width: calc(100% / 4);
					max-width: unset;
					min-width: unset;
					/* margin-bottom: 0px; */
				}

					#product-details .content-block .input-block-titled.select.four .input-holder.select-holder.tiny .select-dropdown {
						width: 180px;
					}

					.circle-button.icon.dark.add-new-nutirition-value-button {
						width: 29px;
						height: 29px;
						line-height: 26px;
					}

						.add-new-nutirition-value-button:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/plus_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/plus_icon.svg") no-repeat center center;
							-webkit-mask-size: 100%;
							mask-size: 100%;
							width: 11px;
							height: 11px;
							background: #fff;
						}

	.full-block.price {
		margin: 30px 0px 0px 0px;
	}

		.full-block.price .content-block,
		.full-block.promotional-price .content-block {
			overflow: unset;
		}

		.full-block.promotional-price .delete-button, .full-block.price .delete-button {
			height: 17px;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}

			.full-block.promotional-price .delete-button:before, .full-block.price .delete-button:before {
				background: #131518;
			}

		.full-block.one .content-block {
			width: 100%;
			padding: 0px;
		}

			.full-block.one .content-block-main {
				margin: 0px;
				background: #fff;
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
			
			}

			.full-block.one .content-block .content-block-intro {
				padding: 25px 35px;
				background: #fff;
				border-top-right-radius: 3px;
				border-top-left-radius: 3px;
			}

			.content-block-header {
				width: 100%;
				box-sizing: border-box;
				padding: 20px 35px;
				background: #EDEDED;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border: 1px solid transparent;
			}

				.content-block-header.border-error {
					border: 1px solid #e74c3c;
				}

				.select-holders {
					display: flex;
					width: 90%;
				}

				#product-details .content-block .select-holders .input-holder {
					margin-right: 20px;
					margin-bottom: 0px !important;
					width: calc(100% / 5);
					max-width: unset;
					min-width: unset;
				}

			.full-block.one .content-block .content-block-table {
				padding: 35px;
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
				position: relative;
			}

				.minimal-table {
					width: 100%;
					border-collapse: collapse;
    				border-spacing: 0;
				}

					.minimal-table-head {
						border-bottom: 1px solid #969DA5;
					}

						.minimal-table-head th {
							color: #292C36;
							font-size: 14px;
							font-weight: normal;
							text-align: left;
							padding: 0px 0px 10px 0px;
						}

						.minimal-table-head th:first-child {
							padding: 0px 0px 10px 10px;
						}

					.minimal-table-body tr {
						border-bottom: 1px solid #D9D9D9;
						cursor: pointer;
						transition: background 0.2s ease-in-out, font-weight 0.2s ease-in-out;
					}

						.minimal-table-body tr:hover {
							background: #f7f7f7;
						}

							.minimal-table-body tr:hover td .delete-button:before {
								background: #F75942 !important;
							}

							.minimal-table-body tr:hover .icon-button.edit-icon {
								background: #F75942;
							}

						.minimal-table-body tr td{
							color: #868686;
							font-size: 14px;
							padding: 6px 0px;
							position: relative;
						}

							.minimal-table-body tr td:first-child {
								padding: 0px 0px 0px 10px;
							}

							.icon-button.edit-icon {
								display: inline-block;
								width: 16px;
								height: 14px;
								display: inline-block;
								mask: url("../../../assets/images/edit_category_icon.svg") no-repeat center center;
								-webkit-mask: url("../../../assets/images/edit_category_icon.svg") no-repeat center center;
								-webkit-mask-size: 100%;
								mask-size: 100%;
								width: 11px;
								height: 13px;
								background: #868686;
								border: 0px;
								cursor: pointer;
								transition: background 0.2s ease-in-out;
							}

							.input-block-titled.select.four .input-holder {
								width: calc(100% / 4);
							}

	.action-buttons {
		position: fixed;
		bottom: 0;
		right: 0;
		box-sizing: border-box;
		padding: 20px;
	}

		.action-buttons button {
			width: 143px;
			margin: 10px 0px;
		}

		.save-button:before {
			content: "";
			display: inline-block;
			width: 13px;
			height: 13px;
			mask: url("../../../assets/images/save_icon.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/save_icon.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			background: #fff;
			transform: scale(1);
			margin: 0px 15px 0px 0px;
			will-change: transform;
			transition: all 0.2s ease-in-out;
		}

		.cancel-button:before {
			content: "";
			display: inline-block;
			width: 16px;
			height: 16px;
			mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			background: #fff;
			transform: scale(1);
			margin: 0px 15px 0px 0px;
			will-change: transform;
			transition: all 0.2s ease-in-out;
		}

	.promotional-price {
		margin: 0px 0px 45px 0px;
	}

	#product-food-metadata .delete-button:before {
		background: #868686;
		margin: 0px;
	}

	#product-food-metadata .delete-button:hover:before {
		background: #F75942;
	}

	#product-details .rounded-data-holder.badges.light.categories-ant .data-line .ant-select {
		width: 100% !important;
		min-height: 46px;
	}

	#product-details .ant-select-selection:focus {
		outline: none !important;
		border-color: transparent;
		box-shadow: none;
	}

		#product-details .ant-select-selection {
			border: 0px;
			outline: none !important;
			border-color: transparent;
			box-shadow: none;
		}

			#product-details .ant-select-selection__rendered {
				margin: 0px;
				line-height: unset;
				position: relative;
				top: 7px;
			}

				#product-details .ant-select-selection__placeholder {
					color: #606060;
				}

			#product-details .ant-select-arrow {
				right: 4px;
			}

			#product-details .ant-select-selection__clear {
				right: 16px;
				margin-top: -6px;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			
				#product-details .ant-select-selection__clear:before {
					content: "\002B";
					display: inline-block;
					color: #AAAAAA;
					background: transparent;
					font-weight: bold;
					font-size: 15px;
				}

				#product-details .ant-select-arrow:before {
					content: "";
					display: inline-block;
					mask: url("../../../assets/images/select-chevron.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/select-chevron.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					width: 7px;
					height: 5px;
					background: #AAAAAA;
				}

.rounded-data-holder.badges.light.categories-ant {
	margin: 0px 0px 20px 0px;
	position: relative;
	pointer-events: auto;
	cursor: pointer;
}

	.rounded-data-holder.badges.light.categories-ant .field-label {
		top: 46px;
		pointer-events: none;
	}

.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
	background-color: transparent;
}

#product-details .ant-select-search__field__placeholder {
	left: 0;
	color: #606060;
	top: 66%;
}

#product-details .ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
	margin-top: 0px;
    height: 21px;
    line-height: 21px;
}

#product-details .categories-ant .field-label {
	font-size: 9px;
	color: #B1B1B1;
	font-weight: normal;
	transform: translate(0px, -57px);
}

.content-block-inner-intro {
    margin: 20px 0px 0px 0px;
}

#product-details .input-holder.full.name {
	margin: 0px 0px 5px 0px;
}

#product-details .detail-description {
	margin: 0px 0px 15px 0px;
}

.promotion-error-massage {
	width: 100%;
    margin: 0px 0px 0px 0px;
    display: inline-block;
}

	.promotion-error-massage h4 {
		color: #e74c3c;
		margin: 0px 0px 0px 0px;
	}

.new-entry-animate {
	animation-name: NEW_ENTRY;
	animation-duration: 1.1s;
}

@keyframes NEW_ENTRY {
	0% { background: #fff; }
	50% { background: whitesmoke }
	0% { background: #fff }
}

.switch-holder.form-product-details-active {
    width: 48%;
    height: 30px;
    flex-wrap: wrap;
    position: relative;
}

	.switch-holder.form-product-details-active .field-label{
		display: block;
		width: 100%;
		font-size: 9px;
		position: absolute;
		top: -9px;
	}

	.switch-holder.form-product-details-active .ant-switch{ 
		display: block;
    	margin: 7px 0px 0px 0px;
	}