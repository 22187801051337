#forms-table #table-settings-dropdown {
	top: 75px;
    right: 20px;
}

#forms-table #table-settings-dropdown.opened {
	top: 80px;
    right: 20px;
}

.bc-button-table-data {
	width: 60px;
}
