.cards {
	display: flex;
	flex-wrap: wrap;
}

	.single-card {
		width: 225px;
		height: 100px;
		position: relative;
		box-sizing: border-box;
		padding: 24px 18px;
		border-radius: 3px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-items: center;
		align-items: center;
		-webkit-align-content: center;
		align-content: center;
		margin: 0px 25px 0px 0px;
	}

		.remove-card {
			width: 8px;
			height: 10px;
			padding: 0px;
			margin: 0px;
			mask: url("../../assets/images/remove_card_icon.svg") no-repeat center center;
			-webkit-mask: url("../../assets/images/remove_card_icon.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			border: 0px;
			text-indent: -9999px;
			cursor: pointer;
			position: absolute;
			right: 18px;
			top: 21px;
		}

		.single-card.products .remove-card {
			background: #883400;
		}

		.single-card.product-types .remove-card {
			background: #A8AEC9;
		}

	.single-card.products {
		background: rgb(247,136,66);
		background: -moz-linear-gradient(-45deg, rgba(247,136,66,1) 0%, rgba(247,89,66,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(247,136,66,1) 0%,rgba(247,89,66,1) 100%);
		background: linear-gradient(135deg, rgba(247,136,66,1) 0%,rgba(247,89,66,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f78842', endColorstr='#f75942',GradientType=1 );
	}

	.single-card.product-types {
		background: rgb(56,66,106);
		background: -moz-linear-gradient(-45deg, rgba(56,66,106,1) 0%, rgba(41,44,54,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(56,66,106,1) 0%,rgba(41,44,54,1) 100%);
		background: linear-gradient(135deg, rgba(56,66,106,1) 0%,rgba(41,44,54,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#38426a', endColorstr='#292c36',GradientType=1 );
	}

	.single-card.stock {
		background: rgb(255,0,0);
		background: -moz-linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(172,0,99,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(255,0,0,1) 0%,rgba(172,0,99,1) 100%);
		background: linear-gradient(135deg, rgba(255,0,0,1) 0%,rgba(172,0,99,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ac0063',GradientType=1 );
	}

	.single-card.on-promotion {
		background: rgb(172,172,0);
		background: -moz-linear-gradient(-45deg, rgba(172,172,0,1) 0%, rgba(44,95,0,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(172,172,0,1) 0%,rgba(44,95,0,1) 100%);
		background: linear-gradient(135deg, rgba(172,172,0,1) 0%,rgba(44,95,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#acac00', endColorstr='#2c5f00',GradientType=1 );
	}

	.single-card.availability-ends {
		background: rgb(0,128,167);
		background: -moz-linear-gradient(-45deg, rgba(0,128,167,1) 0%, rgba(1,168,156,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(0,128,167,1) 0%,rgba(1,168,156,1) 100%);
		background: linear-gradient(135deg, rgba(0,128,167,1) 0%,rgba(1,168,156,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0080a7', endColorstr='#01a89c',GradientType=1 );
	}

	.single-card.hybrid.products {
		background: rgb(247,136,66);
		background: -moz-linear-gradient(-45deg, rgba(247,136,66,1) 0%, rgba(247,89,66,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(247,136,66,1) 0%,rgba(247,89,66,1) 100%);
		background: linear-gradient(135deg, rgba(247,136,66,1) 0%,rgba(247,89,66,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f78842', endColorstr='#f75942',GradientType=1 );
		align-content: space-between;
	}

	.single-card.hybrid.out-of-stock {
		background: rgb(255,0,0);
		background: -moz-linear-gradient(-45deg, rgba(255,0,0,1) 0%, rgba(172,0,99,1) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(255,0,0,1) 0%,rgba(172,0,99,1) 100%);
		background: linear-gradient(135deg, rgba(255,0,0,1) 0%,rgba(172,0,99,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ac0063',GradientType=1 );
		align-content: space-between;
	}

		.single-card.hybrid .single-item {
			margin: 0px 5px 0px 0px;
		}

			.single-card.hybrid .card-description {
				margin: 0px;
				width: 100%;
			}

				.single-card.hybrid .card-icon-title.fat {
					margin: 0px 2px 0px 5px;
				}

		.card-title {
			font-size: 28px;
			font-weight: lighter;
			color: #FFEEE3;
			line-height: 25px;
			width: 100%;
		}

		.single-card.on-promotion .card-title {
			color: #FFFFFF;
		}

		.card-description {
			width: 100%;
			display: flex;
			align-items: center;
			margin: 15px 0px 0px 0px;
			color: #A8AEC9;
		}

			.card-description .card-icon {
				display: inline-block;
				width: 16px;
				height: 16px;
				background: #A8AEC9;
			}

			.single-card.single-card.products .card-description .card-icon {
				mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				background: #883400;
			}

			.single-card.single-card.hybrid .card-description .card-icon {
				mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				background: #883400;
			}
			
			.single-card.product-types .card-description .card-icon {
				mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				background: #A8AEC9;
			}

			.single-card.stock .card-description .card-icon {
				mask: url("../../assets/images/card_out_of_stock_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_out_of_stock_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 20px;
				background: #FFD0D0;
			}

			.single-card.hybrid.out-of-stock .card-description .card-icon {
				mask: url("../../assets/images/card_out_of_stock_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_out_of_stock_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 20px;
				background: #FFD0D0; 
			}

			.single-card.on-promotion .card-description .card-icon {
				mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_producttype_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				background: #F5FFED;
			}
			.single-card.availability-ends .card-description .card-icon {
				mask: url("../../assets/images/card_availability_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/card_availability_icon.svg") no-repeat center center;
				-webkit-mask-size: contain;
				mask-size: contain;
				background: #E6FFFD;
				/* height: 16px; */
			}

			.card-description .card-icon-title {
				color: #A8AEC9;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 14px;
				line-height: 12px;
				display: inline-block;
				margin: 0px 0px 0px 8px;
				letter-spacing: 1px;
			}

			.single-card.products .card-description .card-icon-title {
				color: #883400;
			}

			.single-card.hybrid .card-description .card-icon-title {
				color: #883400;
			}

			.single-card.hybrid .card-description .card-icon-title.fat {
				font-weight: bold;
			}
			
			.single-card.product-types .card-description .card-icon-title {
				color: #A8AEC9;
			}

			.single-card.stock .card-description .card-icon-title {
				color: #FFD0D0;
			}

			.single-card.on-promotion .card-description .card-icon-title {
				color: #F5FFED;
			}

			.single-card.availability-ends .card-description .card-icon-title {
				color: #E6FFFD;
			}

	.single-card.hybrid {
		padding: 13px 18px;
	}

		.single-card.hybrid .items {
			width: 100%;
			display: flex;
			align-items: center;
		}

			.single-card.hybrid .items .item {
				margin: 0px 15px 0px 0px;
				display: flex;
				flex-wrap: wrap;
			}

			.single-card.hybrid .items .item:last-child {
				margin: 0px;
			}

				.item .card-title {
					margin: 0px;
					text-align: center;
				}

				.item span {
					font-weight: bold;
					color: #883400;
					font-size: 10px;
					text-transform: uppercase;
					display: inline-block;
					text-align: center;
					letter-spacing: 1px;
					width: 100%;
					margin: 2px 0px 0px 0px;
				}

				.single-card.hybrid.out-of-stock .item span {
					color: #FFD0D0;
				}

				.single-card.hybrid.out-of-stock .card-description .card-icon-title {
					color: #FFD0D0;
				}

				/* .item.active span {
					position: relative;
					left: 3px;
				} */