#product-categories {
	width: 100%;
    height: 100%;
}

	#product-categories .product-categories-container {
		width: calc(100% - 100px);
		height: 100%;
		display: -webkit-flex;
		display: flex;
		overflow: auto;
		box-sizing: border-box;
		padding: 30px;
	}
	
		.product-categories-container .content-blocks {		
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
			width: 100%;
			height: 100%;
			display: flex;
		}

		/* .content-block {
			width: 50%;
			height: 100%;
			box-sizing: border-box;
			padding: 35px;
			overflow: auto;
		} */

		.content-block.light {
			background: #fff;
		}

		.content-block.dark {
			background: #EDEDED;
		}

			.content-block-intro {
				display: flex;
				align-items: center;
			}

				.content-block-title {
					display: flex;
					align-items: center;
					color: #292C36;
					font-size: 20px;
					font-weight: normal;
				}

					.content-block-icon {
						display: inline-block;
						width: 22px;
						height: 18px;
						margin: 0px 15px 0px 0px;
					}

					.content-block-icon.structure {
						mask: url("../../../assets/images/structure_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/structure_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						background: #F75942;
					}

					.content-block-icon.metadata {
						mask: url("../../../assets/images/cateogry_metadata_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/cateogry_metadata_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						height: 24px !important;
						background: #F75942;
					}

			.content-block-main {
				margin: 20px 0px 0px 0px;
				height: calc(100% - 60px);
			}

				.root-category-disclaimer {
					width: 100%;
					height: 100%;
				}

					.if-empty {
						width: 100%;
						height: 100%;
						position: relative;
					}

				#product-categories .categories-main-list {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
					width: 100%;
					line-height: unset;
					height: unset;
					margin: 0px;
					padding: 0px;
				}

					#product-categories .categories-main-list.drag-over-gap-bottom, 
					#product-categories .categories-main-list.drag-over-gap-top,
					#product-categories .categories-sub-list.drag-over-gap-top,
					#product-categories .categories-sub-list.drag-over-gap-bottom
					{
						border-bottom: 2px dashed #f75942;
					}

				#product-categories .ant-tree li .ant-tree-node-content-wrapper {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					width: calc(100% - 18px);
					border-radius: 3px;
					background: transparent;
					margin: 0px;
					padding: 0px;
					position: relative;
					transition: background 0.2s ease-in-out;
					line-height: unset;
					height: unset;
					border: 0px;
				}

					#product-categories .ant-tree li .categories-sub-list .ant-tree-node-content-wrapper {
						width: 100%;
					}

					#product-categories .ant-tree li .ant-tree-node-content-wrapper .ant-tree-title {
						display: inline-block;
						width: 100%;
						border-bottom: 2px solid transparent;
						box-sizing: border-box;
						transition: all 0.2s ease-in-out;
					}

					#product-categories .main-category {
						display: flex;
						width: 100%;
						padding: 10px;
						align-items: center;
						justify-content: space-between;
						background: transparent;
						transition: background 0.2s ease-in-out;
						border-radius: 3px;
					}

					#product-categories .ant-tree .main-category:hover {
						background: #F7F7F7;
					}
					
					#product-categories .main-category:hover h2:before {
						background: #F75942;
						cursor: move;
					}
					
					#product-categories .main-category:hover .category-options {
						visibility: visible;
						opacity: 1;
						transform: scale(1);
					}

					#product-categories .main-category h2 {
						color: #868686;
						font-size: 14px;
						font-weight: normal;
						text-transform: capitalize;
						margin: 0px;
					}

						#product-categories .main-category h2:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
							-webkit-mask-size: 100%;
							mask-size: 100%;
							width: 16px;
							height: 10px;
							background: #EAEAEA;
							transition: background 0.2s ease-in-out;
							margin: 0px 10px 0px 0px;	
						}

						#product-categories .categories-main-list ul {
							box-sizing: border-box;
							width: 100%;
							padding: 0px 0px 0px 46px;
						}

							#product-categories .categories-sub-list {
								padding: 0px;
								box-sizing: border-box;
								margin: 0px;
								border-bottom: 2px dashed transparent;
							}

								#product-categories .categories-sub-list span.ant-tree-switcher {
									display: none !important;
								}
								
								#product-categories .categories-sub-list .main-category {
									display: flex;
									align-items: center;
									justify-content: space-between;
									padding: 10px;
									background: transparent;
								}

								#product-categories .category-tree li {
									display: flex;
									align-items: center;
									flex-wrap: wrap;
									padding: 0px;
								}

									.ant-tree-child-tree.ant-tree-child-tree-open {
										width: 100%;
										box-sizing: border-box;
										padding: 0px 0px 0px 45px;
									}

								#product-categories .categories-sub-list:last-child {
									margin: 0px 0px 0px 0px;
								}

								#product-categories .categories-main-list .categories-sub-list li:hover {
									background: #F7F7F7;
								}
			
								#product-categories .categories-main-list .categories-sub-list li:hover h2:before {
									background: #F75942;
									cursor: move;
								}
			
								#product-categories .categories-main-list:first-child:hover {
									background: transparent;
								}
								
								#product-categories .categories-main-list .categories-sub-list:hover .category-options {
									visibility: visible;
									opacity: 1;
									transform: scale(1);
								}
				#product-categories .ant-tree li span.ant-tree-switcher {
					width: 18px;
					height: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.category-options {
					display: flex;
					align-items: center;
					/* margin: 0px 15px 0px 0px; */
					visibility: hidden;
					opacity: 0;
					transform: scale(0.8);
					transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
				}

					#product-categories .icon-button {
						display: inline-block;
						margin: 0px;
						padding: 0px;
						border: 0px;
						cursor: pointer;
						margin: 0px 7px;
						background: transparent;
						overflow: visible;
					}

						.icon-button:before {
							background: #606060;
							width: 12px;
							transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
						}

						.icon-button:hover:before {
							background: #333333;
							transform: scale(1.2);
							width: 12px;
						}

						.icon-button.new:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
							-webkit-mask-size: 100%;
							mask-size: 100%;
							height: 12px;
						}

						.icon-button.edit:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/edit_category_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/edit_category_icon.svg") no-repeat center center;
							-webkit-mask-size: 97%;
							mask-size: 97%;
							height: 13px;
							width: 15px;
						}

						.icon-button.delete:before {
							content: "";
							display: inline-block;
							mask: url("../../../assets/images/delete_category_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/delete_category_icon.svg") no-repeat center center;
							-webkit-mask-size: 99%;
							mask-size: 99%;
							height: 13px;
							width: 11px;
						}

						.icon-button.edit {
							margin-right: 5px;
						}

			.content-block.dark .content-block-main {
				margin: 35px 0px 0px 0px;
				position: relative;
			}

				.content-block.dark .content-block-main .empty-state-container {
					width: 100%;
					height: calc(100% - 50px);
					padding: 0px 40px;
				}

					.content-block.dark .content-block-main .empty-state-container .centered-title {
						font-size: 18px; 
					}

					.content-block.dark .content-block-main .empty-state-container .centered-icon {
						width: 56px;
						height: 56px;
					}

			#product-categories .product-categories-container .content-block-main .input-holder {
				margin-bottom: 20px;
			}

			#product-categories .action-buttons {
				position: fixed;
				bottom: 0;
				right: 0;
				box-sizing: border-box;
				padding: 25px;
			}
		
				#product-categories .action-buttons button {
					width: 135px;
					margin: 10px 0px;
				}

				#product-categories .button-holder {
					display: flex;
					align-items: center;
					margin: 30px 0px 0px 0px;
				}

					.button-holder button {
						width: 115px;
						margin: 0px 5px 0px 0px;
					}

					.rounded-button.primary.ghost.cancel-button {
						color: #5F6A78;
					}
		
				.save-button:before {
					content: "";
					display: inline-block;
					width: 13px;
					height: 13px;
					mask: url("../../../assets/images/save_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/save_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #fff;
					transform: scale(1);
					margin: 0px 15px 0px 0px;
					will-change: transform;
					transition: all 0.2s ease-in-out;
				}
		
				.cancel-button:before {
					content: "";
					display: inline-block;
					width: 16px;
					height: 16px;
					mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #fff;
					transform: scale(1);
					margin: 0px 15px 0px 0px;
					will-change: transform;
					transition: all 0.2s ease-in-out;
				}

					.ghost-icon-button.new-category:before {
						content: "";
						display: inline-block;
						mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/new_category_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						height: 12px;
						width: 12px;
						background: #f75942;
						margin: 0px 10px 0px 0px;
					}
	.root-category-disclaimer {
		width: 100%;
		height: 100%;
	}

		.if-empty {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: space-between;
		}

		.root-category-disclaimer .empty-state-container {
			box-sizing: border-box;
			padding: 0px 40px;
			height: calc(100% - 60px);
		}

		.empty-categories h2 {
    		color: #5F6A78;
    		font-size: 16px;
    		font-weight: lighter;
		}

		.category-tree {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			align-content: space-between;
		}

			.ant-tree.ant-tree-icon-hide {
				width: 100%;
				height: calc(100% - 65px);
				overflow: auto;
			}

	#product-categories .input-holder.search {
		margin-top: 20px;
		margin-bottom: 0px !important;
	}

	.items-group {
		box-sizing: border-box;
		background: #f5f5f5;
		border: 1px solid #00000012;
		box-shadow: 0px 0px 15px 1px #00000008;
		margin: 20px 0px 0px 0px;
		max-height: calc(100% - 41px);
		overflow: auto;
		height: auto;
	}
		
		.item-master {
			box-sizing: border-box;
			padding: 10px;
			border-bottom: 1px solid #0000000d;
			margin: 0px 0px 0px 0px;
			opacity: 1;
			cursor: move;
			user-select: none;
			transform: scale(1);
			transform-origin: center;
			transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
		}

			.item-scale-on-drag {
				display: -webkit-flex;
				display: flex;
				align-items: center;
				pointer-events: none;
				transform: scale(1);
				transform-origin: center;
				transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
			}

			.item-master .drop-target {
				transform: scale(0.85);
				opacity: 0.5;
			}

			.item-master:hover {
				opacity: 0.7;
			}

			.items-group .item-master:last-child {
				margin-bottom: 0px;
				border-bottom: none;
			}

			.image {
				width: 40px;
				height: 40px;
				overflow: hidden;
				margin: 0px;
				border: 1px solid #282c362b;
				pointer-events: none;
			}

				.image img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					pointer-events: none;
				}

			.item-info {
				width: calc(100% - 40px);
				box-sizing: border-box;
				padding: 0px 0px 0px 10px;
				pointer-events: none
			}

				.item-name {
					font-size: 16px;
					font-weight: bold;
					margin: 0px 0px 0px 0px;
					color: #282c36;
					pointer-events: none
				}

				.item-description {
					font-size: 12px;
					color: #757575;
					margin: 0px;
					pointer-events: none
				}

		.count {
			display: flex;
			align-items: center;
			pointer-events: none;
		}

			.count-src {
				display: inline-block;
				background: #f75942;
				color: #fff;
				font-weight: bold;
				font-size: 12px;
				line-height: 19px;
				width: 19px;
				height: 19px;
				text-align: center;
				border-radius: 50%;
				margin: 0px 10px 0px 0px;
				pointer-events: none;
			}

			.drag-src {
				display: inline-block;
				mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
				-webkit-mask: url("../../../assets/images/category_drag_icon_dftlt.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 18px;
				width: 21px;
				background: #282c3645;
				transform: rotate(90deg);
				cursor: move;
				pointer-events: none;
			}

#product-categories #tabs {
	height: calc(100% - 45px);
}

	#product-categories #tabs .tabs-container {
		height: 100%;
	}

		#product-categories #tabs .tabs-container .tab-content {
			height: calc(100% - 55px);
		}

		#product-categories .content-block-main.items-block {
			margin: 0px 0px 0px 0px;
			height: 100%;
		}

	.front-back-buttons {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: space-between;
		pointer-events: none;
		position: relative;
		bottom: 1px;
	}

		.icon-button.to-front {
			display: inline-block;
			mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			width: 11px;
			height: 11px;
			background: #282c36 !important;
			-webkit-mask-position-y: 1px;
			margin: 0px !important;
			cursor: pointer;
			pointer-events: auto;
		}

		.icon-button.to-back {
			display: inline-block;
			mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/bring-to-dir.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			width: 11px;
			height: 11px;
			background: #282c36 !important;
			-webkit-mask-position-y: 0px;
			transform: rotate(180deg);
			margin: 0px !important;
			cursor: pointer;
			pointer-events: auto;
		}

		.front-back-buttons .deactivate {
			pointer-events: none;
		}
