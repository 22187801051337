#loader {
    position: absolute;
    width: calc(100% - 56px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

	.loader-centered {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

		.loader-animation {
			width: 110px;
			height: 100px;
			border-radius: 50%;
			overflow: hidden;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			position: relative;
			border-left: 6px solid #00000014;
			border-right: 6px solid #00000014;
			border-top: 6px solid #00000014;
			border-bottom: 6px solid #00000014;
			box-shadow: 0px 0px 45px 10px #00000012;
			box-sizing: border-box;
			animation-name: SphereBorder;
			animation-duration: 0.3s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			animation-fill-mode: forwards;
			animation-direction: alternate-reverse;
		}

			.loader-rocket {
				display: inline-block;
				width: 60px;
				height: 60px;
				mask: url("../../assets/images/space_shuttle_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/space_shuttle_icon.svg") no-repeat center center;
				-webkit-mask-size: 90%;
				mask-size: 90%;
				background: #f75942;
				-webkit-transform: rotate(270deg);
				transform: rotate(270deg);
				position: relative;
				z-index: 2;
				animation-name: RocketJitter;
				animation-duration: 0.8s;
				animation-iteration-count: infinite;
			}

			.loader-stars {
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				mask: url("../../assets/images/stars.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/stars.svg") no-repeat center center;
				mask-size: 110%;
				background: #6060607a;
				mask-repeat: repeat;
				-webkit-mask-repeat: repeat;
				z-index: 0;
				-webkit-mask-position-y: 0;
				animation-name: StarsPosition;
				animation-duration: 0.3s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}

		.loader-disclaimer{
			font-size: 24px;
			font-weight: 100;
			color: #5F6A78;
			margin: 15px 0px 0px 0px;
			padding: 0px 0px 0px 20px;
			display: block;
			width: 100%;
			text-align: center;
			box-sizing: border-box;
		}

		.divider {
			position: absolute;
			width: 31px;
			height: 100%;
			background: -moz-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 43%, rgba(250,250,250,1) 55%, rgba(250,250,250,0) 100%);
			background: -webkit-linear-gradient(left, rgba(250,250,250,0) 0%,rgba(250,250,250,1) 43%,rgba(250,250,250,1) 55%,rgba(250,250,250,0) 100%);
			background: linear-gradient(to right, rgba(250,250,250,0) 0%,rgba(250,250,250,1) 43%,rgba(250,250,250,1) 55%,rgba(250,250,250,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fafafa', endColorstr='#00fafafa',GradientType=1 );
			border: 0px;
			z-index: 1;
			margin: 0px;
		}

@keyframes StarsPosition {
	from {
		-webkit-mask-position-y: 0px;
	}
	to {
		-webkit-mask-position-y: 86px;
	}
}

@keyframes RocketJitter {
	0% {
		transform: rotate(270deg) translate(0px, 0px);
	}
	25% {
		transform: rotate(270deg) translate(2px, 0px);
	}
	50% {
		transform: rotate(270deg) translate(0px, 0px);
	}
	85% {
		transform: rotate(270deg) translate(-2px, 0px);
	}
	100% {
		transform: rotate(270deg) translate(0px, 0px);
	}
}

@keyframes SphereBorder {
	from {
		border-left: 10px solid #00000014;
		border-right: 10px solid #00000014;
		border-top: 0px solid #00000014;
		border-bottom: 0px solid #00000014;
	}
	to {
		border-left: 0px solid #00000014;
		border-right: 0px solid #00000014;
		border-top: 10px solid #00000014;
		border-bottom: 10px solid #00000014;
	}
}