.page-textarea {
	width: 100%;
	height: 600px;
}

	#page-details .content-block-icon {
		width: 14px;
		height: 22px;
	}

		.content-block-icon.page-details {
			mask: url("../../../assets/images/file-alt.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/file-alt.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			background: #F75942;
		}

	#page-details .rounded-data-holder.tags.light {
		margin: 30px 0px 0px 0px;
	}