.single-list {
	background: #fff;
	border: 1px solid #00000017;
	border-radius: 3px;
	box-shadow: 0px 0px 15px 11px #00000005;
	box-sizing: border-box;
	padding: 20px;
}

.single-list.full-width {
	width: 100%;
}

.single-list.percentage-width {
	width: 60%;
}

.single-list.math-width {
	width: calc(100% - 60% - 28px);
}

	.list-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.list-title {
			font-weight: bold;
			color: #282c36;
			font-size: 14px;
			letter-spacing: -0.3px;
			margin: 0px;
			border-bottom: 2px dotted #282c3636;
		}

		.list-link {
			font-size: 13px;
			text-decoration: underline;
			color: #4a90ff;
		}

	.list-values {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 15px 0px 0px 0px;
	}

		.list-values .rdtPicker {
			width: 290px;
		}

		.list-values-divide {
			display: flex;
			align-items: center;
		}

			.list-value {
				font-weight: bold;
				color: #282c36;
				font-size: 20px;
				letter-spacing: -0.3px;
				margin: 0px;
			}

				.list-value span {
					font-weight: 100;
					font-size: 18px;
					color: #22252e;
				}

			.list-percentage .value-src {
				font-weight: normal;
				color: #282c36;
				font-size: 20px;
				line-height: 17px;
				letter-spacing: -0.3px;
				margin: 0px;
			}

			.list-values .input-holder.date-holder.half {
				width: 115px;
				margin: 0px 0px 0px 10px;
			}

	.list-percentage {
		display: flex;
		align-items: center;
	}

		.list-percentage .percentage {
			display: inline-block;
			mask: url("../../assets/images/long-arrow-alt-up.svg") no-repeat center center;
			-webkit-mask: url("../../assets/images/long-arrow-alt-up.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			width: 9px;
			height: 17px;
			margin: 0px 3px 0px 0px;
		}

		.list-percentage.up .value-src {
			color: #2ecc71;
		}

		
		.list-percentage.up .percentage {
			background: #2ecc71;
		}
		
		.list-percentage.down .value-src {
			color: #e74c3c;
		}

		.list-percentage.down .percentage {
			background: #e74c3c;
		}

	.list-graphic {
		margin: 10px 0px 0px 0px;
	}

	.list-map {
		margin: 10px 0px 0px 0px;
	}

		.bc-list-header {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
		}

		.bc-list-header-title {
			font-weight: bold;
			font-size: 14px;
			color: #22252e;
			width: 60%;
		}

		.bc-list-header-values {
			width: 40%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.bc-list-header-values h4 {
				font-weight: bold;
				font-size: 14px;
				color: #22252e;
			}

	.list-item {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

		.item-title {
			font-weight: normal;
			font-size: 14px;
			color: #22252e;
			width: 60%;
		}

		.item-values {
			width: 40%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.item-values h4 {
				font-weight: normal;
				font-size: 14px;
				color: #22252e;
			}