.corner-item {
	position: relative;
	padding: 0px 25px;
	display: flex;
	align-items: center;
}

	.corner-item:first-child:before {
		content: none;
	}

	.corner-item:before {
		content: "";
		display: inline-block;
		width: 1px;
		height: 26px;
		background: #D9D9D9;
		position: absolute;
		left: 0;
	}

	.corner-item:first-child {
		border-left: 0px;
	}

	.corner-item:last-child {
		border-right: 0px;
		padding-right: 0px;
	}

		.corner-item-icon-container {
			position: relative;
			cursor: pointer;
		}

		.corner-item-icon-container:hover .corner-item-icon {
			background: #F75942;
		}

		.corner-item-icon-container:hover .corner-item-count {
			transform: scale(1.08);
			background: #ea2a0e;
		}

			.corner-item-icon {
				display: inline-block;
				width: 21px;
				height: 24px;
				background: #22252E;
				transition: background 0.2s ease-in-out;
			}

			.corner-item.notifications .corner-item-icon {
				mask: url("../../../../assets/images/notification_icon.svg") no-repeat center center;
				-webkit-mask: url("../../../../assets/images/notification_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
			}

		.corner-item-count {
			display: inline-block;
			background: #F75942;
			width: 17px;
			height: 17px;
			border-radius: 50%;
			text-align: center;
			line-height: 17px;
			font-size: 12px;
			font-weight: bold;
			color: #fff;
			position: absolute;
			z-index: 1;
			top: -6px;
			right: -5px;
			transform: scale(1);
			transition: transform 0.2s cubic-bezier(0.83, -0.29, 0.14, 1.13), background 0.2s cubic-bezier(0.83, -0.29, 0.14, 1.13);
		}

	.corner-item-dropdown {
		width: 280px;
		background: #fff;
		position: absolute;
		top: 57px;
		border-radius: 3px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.11);
		z-index: 5;
	}

		.corner-item.notifications .corner-item-dropdown {
			right: 7px;
		}

			.corner-item-dropdown:before {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0px 9px 9px 9px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				right: 19px;
				top: -8px;
			}

		.dropdown-list li {
			background: #fff;
			transition: background 0.2s ease-in-out;
		}

			.dropdown-list li:hover {
				background: whitesmoke;
			}

			.dropdown-list li:first-child {
				border-top-right-radius: 3px;
				border-top-left-radius: 3px;
			}

			.dropdown-list li:last-child {
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
			}

		.dropdown-list li:first-child:hover ~ .dropdown-list > .corner-item-dropdown:before {
			border-color: transparent transparent whitesmoke transparent;
		}

			.dropdown-list li a {
				display: flex;
				align-items: center;
				box-sizing: border-box;
				padding: 16px 14px;
				margin: 0px 7px;
				background: transparent;
				border-bottom: 1px solid #EAEAEA;
			}

				.dropdown-list li.last-item a {
					padding: 9px 0px 11px 0px;
				}

				.dropdown-list li.last-item a .dropdown-data {
					margin: 0px 0px 0px 15px;
				}

				.dropdown-list li:last-child a {
					border-bottom: 0px;
				}

					.dropdown-icon {
						width: 25px;
						height: 25px;
						display: inline-block;
					}

				.corner-item.notifications .dropdown-icon {
					background: #F75942;
				}

				.corner-item.user .dropdown-icon {
					background: #5F6A78;
				}

				.dropdown-icon.upgrade {
					mask: url("../../../../assets/images/notification_type_upgrade_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../../assets/images/notification_type_upgrade_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
				}

				.dropdown-icon.payment {
					mask: url("../../../../assets/images/notification_type_payment_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../../assets/images/notification_type_payment_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
				}

				.dropdown-icon.preferences {
					mask: url("../../../../assets/images/user_dropdown_preferences_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../../assets/images/user_dropdown_preferences_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
				}

				.dropdown-icon.signout {
					mask: url("../../../../assets/images/user_dropdown_signout_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../../assets/images/user_dropdown_signout_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
				}

			.dropdown-data {
				margin: 0px 0px 0px 18px;
			}

			.dropdown-data .dropdown-title {
				color: #22252E;
				font-size: 14px;
				font-weight: normal;
				margin: 0px;
			}

			.dropdown-data .dropdown-date {
				color: #B1B1B1;
				font-size: 12px;
				font-weight: normal;
				margin: 0px;
			}