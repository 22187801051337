#orders {
	width: 100%;
	height: 100%;
}

#orders .table-classic-data.email {
	width: 150px;
    word-break: break-word;
}

.table-classic-data.status {
    letter-spacing: 1px;
    font-weight: bold;
	font-size: 11px;
	white-space: nowrap;
}

	.table-classic-data.status span {
		display: inline-block;
		width: 9px;
		height: 9px;
		background: red;
		border-radius: 50%;
	}

		.table-classic-data.status.canceled span {
			background: #e84118;
		}

		.table-classic-data.status.accepted span {
			background: #11a732;
		}

		.table-classic-data.status.processing span {
			background: #f1c40f;
		}

		.table-classic-data.status.chargeable span {
			background: #282c36;
		}

		.table-classic-data.status.queued span {
			background: #7f8c8d;
		}

		.table-classic-data.status.shipped span {
			background: #8e44ad;
		}

		.table-classic-data.status.delivered span {
			background: #3498db;
		}

		.table-classic-data.status.declined span {
			background: #000;
		}

#orders .table-filter .select-holder {
	border-color: #DEDEDE;
}

	#orders .table-filter .select-holder .select-dropdown{
		/* left: -81px; */
	}

	.date-divider {
		background-color: white;
		color: #f75942;
		font-weight: 900;
		padding: 5px;
	}