#tabs {
	width: 100%;
	margin: 15px 0px 0px 0px;
}

	.tab-titles {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 1px solid #0000001a;
	}

		.tab-title {
			box-sizing: border-box;
			padding: 5px 20px;
			cursor: pointer;
		}
		
		.tab-title:hover h2 {
			color: #2a2c36;
		}
		
		.tab-title h2 {
			margin: 0px;
			font-size: 15px;
			font-weight: normal;
			color: #868686;
			transition: color 0.2s ease-in-out;
		}

		.tab-title.active {
			border-bottom: 2px solid #f75340;
		}

			.tab-title.active h2 {
				font-weight: 600;
    			color: #2a2c36;
			}

	.tab-content {
		margin: 20px 0px 0px 0px;
	}

		.autocomplete-search-intro {
			font-size: 14px;
			margin: 0px;
		}