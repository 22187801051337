#products-table {
    width: 100%;
    height: 100%;
	background: #FAFAFA;
	border-top: 1px solid #D9D9D9;
	box-sizing: border-box;
	position: relative;
}

	.products-table-container {
		width: 100%;
		height: 100%;
	}

		.table-filter {
			width: 100%;
		}

			.filter-row.main {
				box-sizing: border-box;
				padding: 35px 28px 0px 28px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

				.filter-item {
					display: flex;
					align-items: center;
				}

				.filter-icon:before {
					content: "";
					display: inline-block;
					width: 12px;
					height: 12px;
					mask: url("../../../assets/images/filter_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/filter_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #5F6A78;
				}

				.filter-row.main .filter-item.main {
					width: 100%;
				}

					.filter-buttons {
						width: 100%;
						box-sizing: border-box;
						padding: 0px 0px 0px 15px;
					}

						.filter-buttons ul {
							width: 97%;
							display: flex;
							border-bottom: 1px solid #DEDEDE;
						}

							.filter-buttons li {
								margin: 0px 20px 0px 0px;
								border-bottom: 2px solid transparent;
								box-sizing: border-box;
								padding: 0px 0px 2px 0px;
								cursor: pointer;
							}

								.filter-buttons li.active {
									border-bottom: 2px solid #F75942;
								}

								/* .filter-buttons li.active span {
									font-weight: bold;
								} */

								.filter-buttons li span {
									font-size: 16px;
									font-weight: lighter;
									color: #606060;
								}

					.primary-icon:before {
						content: "";
						display: inline-block;
						width: 12px;
						height: 12px;
						mask: url("../../../assets/images/plus_icon.svg") no-repeat center center;
						-webkit-mask: url("../../../assets/images/plus_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						background: #fff;
						margin: 0px 15px 0px 0px;
					}
				
				.filter-row.detail {
					width: 100%;
					display: -webkit-flex;
					display: flex;
					background: #fff;
					border-top: 1px solid #EAEAEA;
					border-bottom: 1px solid #EAEAEA;
					box-sizing: border-box;
					padding: 25px 28px 20px 28px;
					margin: 15px 0px 0px 0px;
					position: relative;
				}

					.filter-row.detail:before {
						content: "";
						display: inline-block;
						width: 23px;
						height: 10px;
						background: url("../../../assets/images/chevrin_disjointed_icon.svg");
						margin: 0px 0px 0px 6px;
						position: absolute;
						top: -10px;
					}

					.filter-row.detail .input-holder {
						margin: 0px 15px 0px 0px;
					}

					.filter-row.detail .input-holder:last-child {
						margin: 0px 0px 0px 0px;
					}

		.products-table-wrap {
			width: 100%;
			box-sizing: border-box;
			padding: 20px 28px 0px 28px;
			height: calc(100% - 74px);
			position: relative;
		}

		.bc-table-row .bc-table-data:nth-child(2n) {
			max-width: 150px;
		}

		/* .bc-table-data.vendor, .bc-table-head .vendor {
			padding-left: 15px;
		} */

						.product-sub {
							font-size: 12px;
							font-weight: normal;
							color: #B2B2B2;
							margin: 3px 0px 0px 0px;
						}

						.table-color-data {
							font-size: 14px;
							font-weight: bold;
							color: #5D890B;
							margin: 0px;
						}

						.product-badge {
							display: inline-block;
							width: 20px;
							height: 20px;
							line-height: 20px;
							text-align: center;
							border-radius: 50%;
							text-transform: uppercase;
							font-size: 11px;
							color: #fff;
							font-weight: bold;
							margin: 0px 5px 0px 0px;
						}

						#products-table .product-badge.product-badge-main {
							margin: 0px 10px 0px 0px;
						}

							.product-badge:last-child {
								margin: 0px;
							}

						.product-badge.f {
							background: #F75942;
						}

						.product-badge.o {
							background: #078010;
						}

						.product-badge.k {
							background: #078080;
						}

						.product-badge.b {
							background: #804807;
						}

						.product-badge.l {
							background: #2C0780;
						}

						.product-badge.p {
							background: #7ed6df;
						}

						.product-badge.v {
							background: #badc58;
						}

						.product-badge.c {
							background: #ff9f1a;
						}

						.settings-icon:before {
							content: "";
							display: inline-block;
							width: 16px;
							height: 16px;
							mask: url("../../../assets/images/settings_icon.svg") no-repeat center center;
							-webkit-mask: url("../../../assets/images/settings_icon.svg") no-repeat center center;
							-webkit-mask-size: 100%;
							mask-size: 100%;
							background: #727272;
						}



#table-settings-dropdown {
	width: 169px;
    background: #fff;
    position: absolute;
    z-index: 1;
    top: 90px;
    border-radius: 3px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
    right: 14px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

	#table-settings-dropdown.opened {
		visibility: visible;
		opacity: 1;
	}

	#table-settings-dropdown:before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0px 9px 9px 9px;
			border-color: transparent transparent #ffffff transparent;
			position: absolute;
			right: 19px;
			top: -8px;
		}

	.dropdown-list li {
		background: #fff;
		transition: background 0.2s ease-in-out;
	}

		.dropdown-list li:hover {
			background: whitesmoke;
		}

		.dropdown-list li:first-child {
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
		}

		.dropdown-list li:last-child {
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
		}

	.dropdown-list li:first-child:hover ~ .dropdown-list > .corner-item-dropdown:before {
		border-color: transparent transparent whitesmoke transparent;
	}

		.dropdown-list li a {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 10px 14px;
			background: transparent;
			border-bottom: 1px solid #EAEAEA;
		}

			.dropdown-list li.last-item a .dropdown-data {
				margin: 0px 0px 0px 15px;
			}

			.dropdown-list li:last-child a {
				border-bottom: 0px;
			}

				.dropdown-icon {
					width: 16px;
					display: inline-block;
				}

			.dropdown-icon {
				background: #F75942;
				margin: 0px 10px 0px 0px;
			}

			.dropdown-icon.edit {
				mask: url("../../../assets/images/edit_icon.svg") no-repeat center center;
				-webkit-mask: url("../../../assets/images/edit_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 16px;
			}

			.dropdown-icon.set-inactive {
				mask: url("../../../assets/images/inactive_icon.svg") no-repeat center center;
				-webkit-mask: url("../../../assets/images/inactive_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				height: 16px;
			}

			.dropdown-icon.delete {
				mask: url("../../../assets/images/delete_icon.svg") no-repeat center center;
				-webkit-mask: url("../../../assets/images/delete_icon.svg") no-repeat center center;
				-webkit-mask-size: 78%;
				mask-size: 78%;
				height: 16px;
			}

		.dropdown-data {
			margin: 0px 0px 0px 18px;
		}

		.dropdown-title {
			color: #22252E;
			font-size: 12px;
			font-weight: normal;
			margin: 0px;
		}

#products-table #loader {
	height: calc(100% - 73px);
}

#products-table .table-classic-data .currency {
	font-weight: 900;
    color: #f75943;
    font-size: 10px;
    position: relative;
    top: 0px;
    text-transform: lowercase;
    margin: 0px 0px 0px 2px;
}
