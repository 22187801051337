#sign-in {
	width: 100%;
	height: 100%;
}

	.sign-in-container {
		width: 100%;
		height: 100%;
		display: flex;
	}

		.sign-in-block.form-data {
			width: 45%;
			height: 100%;
			box-sizing: border-box;
			padding: 45px 25px;
			display: flex;
			align-content: space-between;
			flex-wrap: wrap;
			background: #FAFAFA;
			overflow: auto;
		}

			.logo {
				display: flex;
				align-items: center;
			}

				.logo .logo-text {
					margin: 0px 0px 0px 20px;
					width: auto;
					padding: 0px;
				}

					.logo .logo-icon {
						display: flex;
						align-items: center;
					}

						.logo .logo-icon img {
							width: 46px;
						}

						.logo .logo-text h2 {
							font-size: 22px;
							text-transform: uppercase;
						}

							.logo .logo-text h2 span.b-orange {
								font-weight: normal;
								color: #F75942;
							}

							.logo .logo-text h2 span.c-dark {
								font-weight: bold;
								color: #22252E;
						}

					.logo .b-desc {
						font-weight: lighter;
						font-size: 17px;
						color: #5F6A78;
					}

			.form-data-section {
				width: 100%;
			}

			.form-data-section.fields, 
			.form-data-section.footer {
				box-sizing: border-box;
				padding: 0px 0px 0px 65px;
			}

			.form-data-section.fields {
				margin: 50px 0px;
			}

				.form-data-intro .form-data-title {
					font-size: 26px;
					color: #292C36;
					font-weight: lighter;
				}

				.form-data-intro .form-data-description {
					max-width: 426px;
					width: 90%;
					font-size: 14px;
					color: #606060;
					font-weight: normal;
					line-height: 19px;
					margin: 20px 0px 0px 0px;
				}

			.form-data-fields {
				max-width: 426px;
				width: 90%;
				box-sizing: border-box;
				margin: 39px 0px 0px 0px;
				padding: 45px 33px;
				border-radius: 3px;
				box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.11);
				background: #fff;
			}

				.form-data-additions {
					max-width: 426px;
					width: 90%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 30px 0px 0px 0px;
				}

					.checkbox-holder {
						display: flex;
						align-items: center;
					}

						.checkbox-label {
							color: #606060;
							font-size: 14px;
							font-weight: lighter;
							margin: 0px 0px 0px 8px;
							display: inline-block;
							cursor: pointer;
						}
		
							.checkbox--labeled {
								background: transparent;
								border: 1px solid #707070;
								width: 17px;
								height: 17px;
								border-radius: 3px;
								display: inline-block;
								position: relative;
								cursor: pointer;
								margin: 0px;
							}
								
								.checkbox--labeled:checked:after {
									content: "";
									mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
									-webkit-mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
									-webkit-mask-size: 90%;
									mask-size: 90%;
									background: #FA583A;
									width: 19px;
									height: 19px;
									position: absolute;
									top: -2px;
									left: 2px;
								}
								.checkbox--labeled:hover:after {
									content: "";
									mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
									-webkit-mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
									-webkit-mask-size: 90%;
									mask-size: 90%;
									background: #FA583A;
									width: 19px;
									height: 19px;
									position: absolute;
									top: -2px;
									left: 2px;
									opacity: 0.3;
								}
								.checkbox--labeled:checked:hover:after {
									content: "";
									mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
									-webkit-mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
									-webkit-mask-size: 90%;
									mask-size: 90%;
									background: #FA583A;
									width: 19px;
									height: 19px;
									position: absolute;
									top: -2px;
									left: 2px;
									opacity: 1;
								}

				.form-data-link {
					color: #606060;
					font-size: 14px;
					font-weight: lighter;
					white-space: nowrap;
				}

					.form-data-action {
						margin: 20px 0px 0px 0px;
					}

						.login-button {
							width: 143px;
							font-size: 16px;
						}
		
			.form-data-section.footer {
				/* margin: 236px 0px 0px 0px; */
			}

				.form-data-paragraph {
					color: #606060;
					font-size: 14px;
					line-height: 19px;
					font-weight: normal;
					width: 90%;
					max-width: 80%;
				}

				.form-data-copyright {
					display: inline-block;
					margin: 10px 0px 0px 0px;
					color: #292C36;
					font-size: 14px;
					line-height: 19px;
					font-weight: bold;
				}

		.sign-in-block.disclaimer-data {
			background: #22252E;
			width: 55%;
			height: 100%;
			box-sizing: border-box;
			padding: 0px 75px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url("../../../assets/images/sign_in_rocket.svg");
			background-repeat: no-repeat;
			background-position: 210px 70px;
			background-size: cover;
		}

			.disclaimer-data-centered {
				display: flex;
				flex-wrap: wrap;
				align-content: space-between;
				height: 38%;
				margin: 0px 0px 200px 0px;
			}

				.disclaimer-section.intro h2 {
					color: #5F6A78;
					font-size: 26px;
					font-weight: lighter;
				}

				.disclaimer-section.description {
					margin: 50px 0px;
				}

					.disclaimer-section.description h2 {
						font-size: 30px;
						font-weight: lighter;
					}

						.disclaimer-section.description h2 span.title-white {
							color: #fff;
						}

						.disclaimer-section.description h2 span.title-orange {
							color: #F75942;
						}

					.description-paragraph {
						color: #fff;
						font-size: 14px;
						line-height: 22px;
						font-weight: lighter;
						margin: 25px 0px 0px 0px;
						max-width: 60%;
					}

				.disclaimer-section.outro .outro-paragraph {
					color: #5F6A78;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					max-width: 60%;
				}

					.disclaimer-section.outro .outro-paragraph a{
						color: #F75942;
						text-decoration: underline;
					}