.single-chart {
	min-width: 380px;
	background: #fff;
	border: 1px solid #00000017;
	border-radius: 3px;
	box-shadow: 0px 0px 15px 11px #00000005;
	box-sizing: border-box;
	padding: 20px;
}

.single-chart.full-width {
	width: 100%;
}

.single-chart.percentage-width {
	width: 60%;
}

.single-chart.push-right {
	margin: 0px 28px 0px 0px;
}

	.chart-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.chart-title {
			font-weight: bold;
			color: #282c36;
			font-size: 14px;
			letter-spacing: -0.3px;
			margin: 0px;
			border-bottom: 2px dotted #282c3636;
		}

		.chart-link {
			font-size: 13px;
			text-decoration: underline;
			color: #4a90ff;
		}

	.chart-values {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 15px 0px 0px 0px;
	}

		.chart-values .rdtPicker {
			width: 290px;
		}

		.chart-values-divide {
			display: flex;
			align-items: center;
		}

			.chart-value {
				font-weight: bold;
				color: #282c36;
				font-size: 20px;
				letter-spacing: -0.3px;
				margin: 0px;
			}

				.chart-value span {
					font-weight: 100;
					font-size: 16px;
					color: #22252e;
				}

			.chart-percentage .value-src {
				font-weight: normal;
				color: #282c36;
				font-size: 20px;
				line-height: 17px;
				letter-spacing: -0.3px;
				margin: 0px;
			}

			.chart-values .input-holder.date-holder.half {
				width: 115px;
				margin: 0px 0px 0px 10px;
			}

	.chart-percentage {
		display: flex;
		align-items: center;
	}

		.chart-percentage .percentage {
			display: inline-block;
			mask: url("../../assets/images/long-arrow-alt-up.svg") no-repeat center center;
			-webkit-mask: url("../../assets/images/long-arrow-alt-up.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			width: 9px;
			height: 17px;
			margin: 0px 3px 0px 0px;
		}

		.chart-percentage.up .value-src {
			color: #2ecc71;
		}

		
		.chart-percentage.up .percentage {
			background: #2ecc71;
		}
		
		.chart-percentage.down .value-src {
			color: #e74c3c;
		}

		.chart-percentage.up.neutral .value-src {
			color: #5F6A78;
		}

		.chart-percentage.up.neutral .percentage {
			background: #5F6A78;
		}
		
		.chart-percentage.down .percentage {
			background: #e74c3c;
			transform: rotate(180deg);
		}

	.chart-graphic {
		margin: 10px 0px 0px 0px;
		position: relative;
		height: calc(100% - 75px);
	}

		.empty-chart-disclaimer {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

			.centered {
				width: 70%;
				text-align: center;
			}

				.empty-chart-icon {
					display: inline-block;
					mask: url("../../assets/images/chart-line-solid.svg") no-repeat center center;
					-webkit-mask: url("../../assets/images/chart-line-solid.svg") no-repeat center center;
					-webkit-mask-size: contain;
					mask-size: contain;
					width: 30px;
					height: 30px;
					margin: 0px 0px 0px 0px;
					background: #5F6A78;
				}

				.centered h2 {
					font-size: 18px;
					line-height: 26px;
					color: #5F6A78;
					margin: 10px 0px 0px 0px;
				}

	.empty-chart-disclaimer.orders-per-hour-dsclmr {
		height: 250px;
	}

	.empty-chart-disclaimer.average-order-price-dsclmr {
		height: 250px;
	}