#company-details {
	width: calc(100% - 135px);
	height: 100%;
}

	#company-details .input-grid {
		margin: 30px 0px 0px 0px;
	}

		#company-details .input-grid .input-holder {
			margin-bottom: 30px;
		}
		
		#company-details .input-grid .input-holder.company-phone {
			margin-bottom: 15px;
		}