#toast {
	width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 28px;
    box-shadow: 0px 10px 21px 10px #0000001c;
    position: fixed;
    top: 73px;
    z-index: 4;
}

.toast-container.success {
	border-top: 1px solid #272c3654;
    border-bottom: 1px solid #272c3654;
	background: #78e08f;
}

.toast-container.error {
	border-top: 1px solid #272c3654;
	border-bottom: 1px solid #272c3654;
	background: #e55039;
}

	.toast-icon {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		margin: 0px 10px 0px 0px;
		border-radius: 50%;
		box-sizing: border-box;
	}

	.toast-container.success .toast-icon {
		background: #11a732;
		border: 2px solid #fff;
	}

	.toast-container.error .toast-icon {
		background: #b73521;
		border: 2px solid #fff;
	}

		.toast-container .toast-icon:before {
			content: "";
			display: inline-block;
			width: 14px;
			height: 14px;
			-webkit-mask-size: 100%;
			mask-size: 100%;
		}

		.toast-container.success .toast-icon:before {
			mask: url("../../assets/images/check_double_icon.svg") no-repeat center center;
			-webkit-mask: url("../../assets/images/check_double_icon.svg") no-repeat center center;
			background: #fff;
		}

	.toast-container.error .toast-icon:before {
		mask: url("../../assets/images/bomb_icon.svg") no-repeat center center;
		-webkit-mask: url("../../assets/images/bomb_icon.svg") no-repeat center center;
		background: #fff;
	}

	#toast h2 {
		margin: 0px;
		font-size: 16px;
		font-weight: 400;
	}

	.toast-container.success h2 {
		color: #272c36c7;
	}

	.toast-container.error h2 {
		color: #fff;
	}