#header {
    width: 100%;
    height: 73px;
    background: #EDEDED;
    border-bottom: 1px solid #D9D9D9;
}

	.header-container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 28px;
	}

		.nav-history-list {
			display: flex;
		}

			.nav-history-list li {
				display: flex;
				align-items: center;
			}

				.nav-history-list li:last-child:after {
					content: none;
				}

				.nav-history-list li:after {
					content: "";
					display: inline-block;
					width: 6px;
					height: 10px;
					mask: url("../../../../assets/images/chevron_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../../assets/images/chevron_icon.svg") no-repeat center center;
					background: #D9513E;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					margin: 0px 12px;
				}
			
			.nav-history-list li a {
				color: #5F6A78;
				font-size: 18px;
				font-weight: lighter;
				text-transform: capitalize;
			}

		.user-corner {
    		display: flex;
		}