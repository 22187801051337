#alert {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 8;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

	.alert-overlay {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(33, 36, 46, 0.7411764705882353);
	}

	.alert-container {
		width: 400px;
		background: #fff;
		border-radius: 3px;
		position: relative;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		padding: 10px;
	}

		.alert-header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			background: #22252E;
		}

			.alert-header-title {
				margin: 0px;
				font-size: 16px;
				color: #fff;
				font-weight: 400;
			}

			#alert .close-alert-button {
				width: 18px;
				height: 18px;
			}

				#alert .close-alert-button:before {
					width: 100%;
					height: 100%;
					mask: url("../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask: url("../../assets/images/x_icon.svg") no-repeat center center;
					background: #fff;
					-webkit-mask-size: 100%;
					mask-size: 100%;
				}

				.alert-data {
					margin: 10px 0px 0px 0px;
					box-sizing: border-box;
					padding: 15px 10px;
					background: whitesmoke;
				}

					.alert-data-description {
						font-size: 16px;
						line-height: 22px;
						margin: 0px;
						color: #5d5d5d;
						font-weight: normal;;
					}

				.alert-actions {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin: 20px 0px 0px 0px;
				}

					.delete-button:before {
						content: "";
						display: inline-block;
						width: 13px;
						height: 15px;
						mask: url("../../assets/images/delete_category_icon.svg") no-repeat center center;
						-webkit-mask: url("../../assets/images/delete_category_icon.svg") no-repeat center center;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						background: #fff;
						-webkit-transform: scale(1);
						transform: scale(1);
						margin: 0px 15px 0px 0px;
						will-change: transform;
						transition: all 0.2s ease-in-out;
					}