.empty-state-container {
    position: absolute;
    width: calc(100% - 58px);
    height: calc(100% - 160px);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

	.content-centered {
		text-align: center;
	}

		.centered-icon {
			display: inline-block;
			width: 66px;
			height: 66px;
			background: #727272;
			opacity: 0.2;
			animation-name: RotateIn;
			animation-duration: 0.5s;
			animation-delay: 0s;
		}

			.centered-icon.rocket {
				mask: url("../../assets/images/space_shuttle_icon.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/space_shuttle_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				transform: rotate(-20deg);
			}

			.centered-icon.cursor {
				mask: url("../../assets/images/mouse_pointer.svg") no-repeat center center;
				-webkit-mask: url("../../assets/images/mouse_pointer.svg") no-repeat center center;
				-webkit-mask-size: 50%;
				mask-size: 50%;
				transform: rotate(-20deg)
			}

		.centered-title {
			font-size: 24px;
			font-weight: 100;
			color: #5F6A78;
			margin: 15px 0px 0px 0px;
			animation-name: FadeIn;
			animation-duration: 1s;
			animation-iteration-count: 1;
			animation-direction: normal;
			animation-iteration-count: forwards;
		}


/* 
	ROCKET [ICON] ANIMATION
*/

@keyframes RotateIn {
	from {
		transform: rotate(-40deg);
	}
  
	to {
		transform: rotate(-20deg);
	}
}

@keyframes FadeIn {
	from {
		opacity: 0;
	}
  
	to {
		opacity: 1;
	}
}