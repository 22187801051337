/* .user-details-container {
	width: calc(100% - 130px);
    height: 100%;
    display: -webkit-flex;
    display: flex;
    overflow: auto;
    box-sizing: border-box;
    padding: 30px;
}

	.user-details-header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.cbi-header {
			box-sizing: border-box;
			padding: 35px;
		}

		.user-header-left {
			display: flex;
			align-items: center;
		}

			.user-header-left .content-block-title {
				margin: 0px 0px 0px 20px;
			}

		.user-header-right p.input-holder.select-holder.half {
			width: 140px;
			margin: 10px 0px 0px 0px;
		}

		.content-blocks {
			width: 100%;
		}

			#user-details .content-block {
				width: 100%;
				box-sizing: border-box;
				padding: 0px;
				overflow: hidden;
				border-radius: 3px;
				box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
			}

				#user-details .content-block-main {
					margin: 0px;
				}

				.content-block-intro.general-information {
					margin: 0px 0px 0px 0px;
					box-sizing: border-box;
					padding: 20px 35px;
					background: #f9f9f9;
					border-top: 1px solid #f75942;
					border-bottom: 1px solid #f75942;
				} */

					/* #user-details .content-block-main .input-grid.basics {
						box-sizing: border-box;
						padding: 45px 35px;
					}
					#user-details .content-block-main .input-grid.mapped {
						box-sizing: border-box;
						padding: 35px;
					}

					#user-details .content-block-main .input-grid .input-holder.half {
						margin-bottom: 35px;
					} */

					/* .input-grid-mapped {
						width: 100%;
						box-sizing: border-box;
						margin: 0px 0px 10px 0px;
						box-shadow: 0px 0px 25px 6px #00000012;
					} */

						/* .input-grid-mapped:last-child {
							margin-bottom: 0px;
						} */

						/* #user-details .input-grid.basics .input-holder:last-of-type {
							margin-bottom: 0px;
						} */

						#user-details .content-block-main .input-grid .input-holder.half {
							margin-bottom: 35px;
						}

						.collapsible-trigger {
							width: 100%;
							box-sizing: border-box;
							padding: 10px 15px;
							background: whitesmoke;
							cursor: pointer;
							border-radius: 3px;
						}

							.collapsible-trigger h2:before {
								content: "";
								display: block;
								mask: url("../../../assets/images/chevron_icon.svg") no-repeat center center;
								-webkit-mask: url("../../../assets/images/chevron_icon.svg") no-repeat center center;
								-webkit-mask-size: 100%;
								mask-size: 100%;
								width: 6px;
								height: 12px;
								background: #F75942;
								margin: 0px 10px 0px 0px;
								transition: all 0.3s ease-in-out;
							}

							.collapsible-trigger h2 {
								margin: 0px;
								color: #5F6A78;
								font-size: 16px;
								font-weight: lighter;
								text-transform: capitalize;
								display: flex;
								align-items: center;
							}

						.collapsible-content {
							width: 100%;
							box-sizing: border-box;
							padding: 0px 15px 0px 15px;
							display: -webkit-flex;
							display: flex;
							-webkit-align-items: center;
							align-items: center;
							-webkit-justify-content: space-between;
							justify-content: space-between;
							-webkit-flex-wrap: wrap;
							flex-wrap: wrap;
							max-height: 0px;
							opacity: 0;
							overflow: hidden;
							transition: all 0.3s ease-in-out;
							border: 1px solid #00000014;
							border-radius: 3px;
						}

							.expand .collapsible-content {
								opacity: 1;
								max-height: 300px;
								padding: 28px 20px 20px 20px;
								overflow: visible;
								margin: 10px 0px 0px 0px;
							}

							.expand .collapsible-trigger h2:before {
								transform: rotate(90deg);
							}

							.collapsible-content .input-holder-inside {
								width: 48%;
								max-width: 48%;
								margin: 0px 0px 35px 0px;
							}

								#user-details .collapsible-content .input-holder-inside .input-holder.half {
									margin: 0px 0px 0px 0px;
								}

							.input-holder-inside {
								display: flex;
								align-items: center;
								justify-content: space-between;
							}

							#user-details .input-grid-mapped.expand .collapsible-content .input-holder:last-child {
								margin-bottom: 0px;
							}
							
							#user-details .input-grid-mapped.expand .collapsible-content .input-holder:nth-last-child(2) {
								margin-bottom: 0px;
							}

							.collapsible-trigger.edit-mode {
								border: 1px solid #f75942;
							}

							#user-details .input-grid-mapped {
								margin: 10px 0px 0px 0px;
							}