#users-table {
    width: 100%;
    height: 100%;
	background: #FAFAFA;
	border-top: 1px solid #D9D9D9;
	box-sizing: border-box;
	position: relative;
}

	#users-table .content-block-main .input-holder.half input, #users-table .content-block-main .input-holder.full input, #users-table .content-block-main .select-holder.half .select-value, #users-table .content-block-main .textarea-holder textarea, #users-table .content-block-main .select-holder.full input, #users-table .content-block-main .input-holder.date-holder input, #users-table .select-value {
		border-bottom: 1px solid #DEDEDE;
	}

	.users-table-container {
		width: 100%;
		height: 100%;
	}

	.table-filter {
		width: 100%;
	}

		.filter-row.main {
			box-sizing: border-box;
			padding: 35px 28px 0px 28px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.filter-item {
				display: flex;
				align-items: center;
			}

			.filter-icon:before {
				content: "";
				display: inline-block;
				width: 12px;
				height: 12px;
				mask: url("../../../assets/images/filter_icon.svg") no-repeat center center;
				-webkit-mask: url("../../../assets/images/filter_icon.svg") no-repeat center center;
				-webkit-mask-size: 100%;
				mask-size: 100%;
				background: #5F6A78;
			}

			.filter-row.main .filter-item.main {
				width: 100%;
			}

				.filter-buttons {
					width: 100%;
					box-sizing: border-box;
					padding: 0px 0px 0px 15px;
				}

					.filter-buttons ul {
						width: 97%;
						display: flex;
						border-bottom: 1px solid #DEDEDE;
					}

						.filter-buttons li {
							margin: 0px 20px 0px 0px;
							border-bottom: 2px solid transparent;
							box-sizing: border-box;
							padding: 0px 0px 2px 0px;
							cursor: pointer;
						}

							.filter-buttons li.active {
								border-bottom: 2px solid #F75942;
							}

							/* .filter-buttons li.active span {
								font-weight: bold;
							} */

							.filter-buttons li span {
								font-size: 16px;
								font-weight: lighter;
								color: #606060;
							}


			.users-table-wrap .filter-row.detail .input-holder {
				max-width: 200px;
			}

			.bc-image-holder {
				width: 42px;
				height: 42px;
			}

			.bc-table-data.user-name {
				font-size: 16px;
				font-weight: 600;
				color: #606060;
				max-width: 100px;
				line-height: 24px;
				margin: 0px;
			}

			/* .table-classic-data {
				font-size: 16px;
				font-weight: normal;
				color: #606060;
			} */

			td.bc-table-data.user-role, td.bc-table-data.user-gender {
				text-transform: capitalize;
			}

				#users-table .icon-button {
					width: 30px;
					height: 30px;
					background: white;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					margin: auto;
				}

					#users-table .filter-buttons ul {
						width: 100%;
					}

				.icon-button.confirmed, .icon-button.active {
					border: 2px solid #39b6557a;
				}

				.icon-button.not-confirmed, .icon-button.inactive {
					border: 2px solid #e550397a;
				}

				#user-details .action-buttons button {
					width: 113px;
				}

				.confirmed-icon.confirmed:before {
					width: 14px;
					height: 14px;
					mask: url("../../../assets/images/check_double_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/check_double_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #11a732; 
				}

				.confirmed-icon.not-confirmed:before {
					width: 18px;
					height: 18px;
					mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #e55039; 
				}

				.active-icon.active:before {
					width: 14px;
					height: 14px;
					mask: url("../../../assets/images/check_double_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/check_double_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #11a732; 
				}

				.active-icon.inactive:before {
					width: 14px;
					height: 14px;
					mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask: url("../../../assets/images/x_icon.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #e55039; 
                }
                
                .content p{
                    width: 250px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
				}
				
#pages-table .content p {
	width: 200px;
}

#pages-table .table-classic-data {
	width: 200px;
}