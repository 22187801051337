#warehouse-table .table-classic-data {
	overflow: hidden;
    text-overflow: ellipsis;
}

#warehouse-table .bc-table-data.status h2 {
	display: inline-block;
    background: #2ecc71;
    width: auto !important;
	box-sizing: border-box;
	font-size: 12px;
    padding: 2px 7px 1px 7px;
    border-radius: 5px;
    color: #fff;
    font-weight: normal;
}

#warehouse-table .bc-table-avatar {
    display: flex;
    align-items: center;
}

#warehouse-table .avatar-hodler {
    width: 30px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
	border-radius: 50%;
    margin: 0px 0px 3px 0px;
}

#warehouse-table .avatar-hodler img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#warehouse-table .name-holder {
    width: calc(100% - 30px);
    font-size: 14px;
    font-weight: normal;
    margin: 0px 0px 0px 0px !important;
    line-height: 16px;
	height: auto !important;
	box-sizing: border-box;
    padding: 0px 0px 0px 5px;
}

#warehouse-table .name-holder h2 {
	font-size: 14px;
	margin: 0px 0px 1px 0px;
}

#warehouse-table .name-holder h4 {
    margin: 0px;
    color: #8e8e8e;
    font-size: 12px;
}


#warehouse-table .empty-cell {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin: 0px 0px 0px 20px;
	mask: url("../../../assets/images/remove_card_icon.svg");
	-webkit-mask: url("../../../assets/images/remove_card_icon.svg");
	mask-size: 100%;
	-webkit-mask-size: 100%;
	background: #c3c3c3;
}

#warehouse-table #table-settings-dropdown {
    width: 169px;
    right: 6px;
}

#warehouse-table .empty-state-container {
	top: 200px;
}

#warehouse-details .product-search .content-block-main {
	display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
	margin: 25px 0px 0px 0px;
	height: auto;
}

#warehouse-details .product-search .content-block-main p {
    width: 50%;
}

#warehouse-details .content-block.dark.products {
    background: #EDEDED;
    border: 1px solid #f75942;
}

#warehouse-details .content-block.light {
    padding: 35px;
}

#warehouse-details .full-block.one .content-block .content-block-intro {
	padding: 0px;
}

#warehouse-details .single-item {
    margin: 0px 0px 0px 0px;
    padding: 0px 20px 0px 20px;
    position: relative;
}

#warehouse-details .single-item:first-child {
    padding-left: 0px;
}

#warehouse-details .single-item:before {
    content: "";
    width: 1px;
    height: 50px;
    background: #dedede;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.si-intro {
    font-weight: normal;
    font-size: 12px;
    color: #f75942;
    margin: 0px;
}

.si-title {
    font-size: 14px;
    font-weight: bold;
    color: #606060;
    margin: 4px 0px 0px 0px;
}

.si-title.no-data {
	font-weight: normal;
}

h2.si-title span {
    font-size: 10px;
    color: #f75942;
    font-weight: normal;
}

#warehouse-details .full-block.one .content-block .content-block-table {
	width: 100%;
	padding: 0px;
}

.content-block.dark.product-search.border {
	border: 1px solid #f75942;
    padding: 0px 35px;
    display: flex;
    align-items: center;
}

#warehouse-details .content-block.light.product-search.filter .content-block-main {
	justify-content: space-between;
}

#warehouse-details .content-block.light.product-search.filter {
	width: 50%;
}

#warehouse-details .cbm-single-avatar-holder {
    width: 40px;
    height: 40px;
    overflow: hidden;
}

#warehouse-details .cbm-single-avatar-holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#warehouse-details .cbm-single.avatar-title {
	display: flex;
    align-items: center;
}

.cbm-single-data {
    margin: 0px 0px 0px 10px;
}

.cbm-single-data h2 {
    font-size: 16px;
    font-weight: bold;
	margin: 0px;
	color: #606060;
}

.cbm-single-data h4 {
    font-size: 14px;
    font-weight: normal;
	margin: 0px;
	color: #ababab;
}

.cbm-single.price {
    align-items: center;
}

.cbm-single.price h2 {
    color: #f75942;
    font-weight: bold;
    margin: 0px;
    line-height: 20px;
}

.cbm-single.price span {
	margin: 0px;
	line-height: 20px;
	color: #ababab;
	font-size: 12px;
}

#warehouse-details .product-search.content-block.dark.product-search.border .content-block-main {
	-webkit-justify-content: space-between !important;
    justify-content: space-between !important;
    margin: 0px;
    align-items: center;
    width: 100%;
}

#warehouse-details .empty-centered-data {
    text-align: center;
    margin: 0px 0px 0px 0px;
    font-size: 16px;
    color: darkgrey;
    font-weight: 100;
    width: 100%;
}

.cbm-single-data h2 span {
    font-size: 16px;
	color: #22242d;
	white-space: nowrap;
}

.cbm-single-data h2 span .price-curency {
    color: #f75942;
    font-weight: normal;
    font-size: 12px;
}

#warehouse-details .minimal-table-body .input-holder {
	width: unset !important;
}

#warehouse-details .minimal-table-head th {
	/* white-space: nowrap; */
}

#warehouse-details .rounded-button.primary.primary-icon.gradient.blue.save-button.import {
    width: 185px;
    margin: 10px 0px;  
}

#warehouse-details .rounded-button.primary.primary-icon.gradient.approve {
    width: 170px;
    margin: 5px;
}

#warehouse-details .action-buttons {
    display: flex;
}

.content-block-intro.iiid-data {
    margin: 30px 0px 0px 0px;
}

#warehouse-table td.bc-table-data.status.retracted h2 {
    background: #f1c40f;
    color: white;
}

#warehouse-table td.bc-table-data.status.new h2 {
    background: #F75942;
    color: white;
}

#warehouse-table td.bc-table-data.status.verified h2 {
    background: #2ecc71;
    color: white;
}

#warehouse-details .content-block.dark.product-search.border.product-chooser .input-holder{
    width: 100%;
    max-width: unset;
    margin: 0px 0px 20px 0px;
}

#warehouse-details .content-block.dark.product-search.border.product-chooser .content-block-main {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: space-between;
    align-content: normal;
    box-sizing: border-box;
    /* padding: 0px 0px 25px 0px; */
    margin: 0px 0px 4px 0px;
}

#warehouse-details .remove-td {
    padding: 0px;
    width: 20px !important;
    max-width: 20px !important;
    min-width: 20px !important;
}

#warehouse-details .remove-td button {
	padding: 0px;
}

#warehouse-details .content-block.dark.product-search.border.product-chooser .content-block-main.disabled .input-holder .select-dropdown{
	pointer-events: none !important;
}

#warehouse-details .content-block.light.product-search .minimal-table-body h2 {
    color: #868686;
	font-size: 14px;
	margin: 0px;
}

#warehouse-details .minimal-table {
	table-layout: fixed;
}

#warehouse-details .delete-button:before {
	width: 18px;
	height: 18px;
	mask-size: 74%;
	-webkit-mask-size: 74%;
}

#warehouse-details .block-footer {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    background: #ededed;
    border: 1px solid #0000000d;
	margin: 20px 0px 0px 0px;
	display: flex;
    align-items: center;
    justify-content: flex-start;
}

.single-footer-block h2 {
	font-size: 14px;
	color: #868686;
	margin: 0px 0px 0px 0px;
}

.single-footer-block {
    margin: 0px 20px 0px 0px;
}

.sfb-value {
    font-weight: bold;
    color: #22252e;
}

.sfb-value span {
	text-transform: uppercase;
	color: #F75942;
	font-size: 10px;
}

#warehouse-details .minimal-table-body tr td span {
	text-transform: uppercase;
	color: #F75942;
	font-size: 10px;
}

#warehouse-details .product-search .content-block-main .input-holder.full {
	width: 100%;
}