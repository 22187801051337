#dashboard {
    width: 100%;
    height: 100%;
}

	#cards {
		width: 100%;
		box-sizing: border-box;
		padding: 28px;
		background: #ffffff;
		background: -moz-linear-gradient(top, #ffffff 0%, #ededed 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%,#ededed 100%);
		background: linear-gradient(to bottom, #ffffff 0%,#ededed 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 );
		border-bottom: 1px solid #D9D9D9;
	}

	.dashboard-container {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 28px;
	}

		.charts {
			display: flex;
			flex-wrap: wrap;
		}

		.chart-group {
			width: 100%;
			margin: 28px 0px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

			.chart-values .select-holder {
				width: 120px;
				margin: 0px 0px 2px 10px;
			}