#order-details {
	width: calc(100% - 135px);
    height: 100%;
}

	/* #order-details .full-block {
		margin: 0px 0px 30px 0px;
	} */

		#order-details .content-block-main .input-holder {
			margin: 0px 0px 20px 0px;
		}

		#order-details .content-block-intro {
			margin: 10px 0px 10px 0px;
		}

			#order-details .content-block-intro:first-child {
				margin-top: 0px;
			}
		
		#order-details .content-block-intro.delivery-details {
			margin-bottom: 20px;
		}

		.order-id-disabled {
			display: flex;
			align-items: center;
			background: #fafafa;
			border: 1px solid #00000008;
			box-sizing: border-box;
			padding: 5px 10px;
			margin: 25px 0px 38px 0px;
		}

			.order-id-disabled h2 {
				font-weight: bold;
				font-size: 14px;
				color: #292C36;
				margin: 0px;
			}

			.order-id-disabled span {
				font-size: 14px;
				margin: 0px 0px 0px 5px;
				color: #292c36;
			}

			#order-details .content-block-table {
				margin: 22px 0px 0px 0px;
			}

			.total-output {
				width: 100%;
				text-align: right;
				box-sizing: border-box;
				background: #fafafa;
				margin: 10px 0px 0px 0px;
				padding: 5px 10px;
				border: 1px solid #00000008;
			}

				.total-output h2 {
					margin: 0px;
					font-weight: bold;
					font-size: 16px;
					color: #292C36;
				}

					.total-output h2 span {
						font-weight: normal;
					}
	
	#order-details .content-block-icon {
		display: inline-block;
		width: 18px;
		height: 20px;
		margin: 0px 15px 0px 0px;
	}

	#order-details .content-block-icon.customer-details {
		mask: url("../../../assets/images/user.svg") no-repeat center center;
		-webkit-mask: url("../../../assets/images/user.svg") no-repeat center center;
		-webkit-mask-size: 100%;
		mask-size: 100%;
		background: #F75942;
	}

	#order-details .content-block-icon.delivery-details {
		mask: url("../../../assets/images/truck.svg") no-repeat center center;
		-webkit-mask: url("../../../assets/images/truck.svg") no-repeat center center;
		-webkit-mask-size: 100%;
		mask-size: 100%;
		background: #F75942;
	}

	#order-details .content-block-icon.order-items {
		mask: url("../../../assets/images/layer-group.svg") no-repeat center center;
		-webkit-mask: url("../../../assets/images/layer-group.svg") no-repeat center center;
		-webkit-mask-size: 100%;
		mask-size: 100%;
		background: #F75942;
	}

	#order-details .content-block-icon.order-status-icon {
		mask: url("../../../assets/images/list.svg") no-repeat center center;
		-webkit-mask: url("../../../assets/images/list.svg") no-repeat center center;
		-webkit-mask-size: 100%;
		mask-size: 100%;
		background: #F75942;
	}

	#order-details .order-status .select-holder {
		margin: 30px 0px 40px 0px;
	}

	.no-items-disclaimer h2 {
		margin: 0px;
		color: #868686;
		font-size: 14px;
		padding: 10px 10px 10px 0px;
		text-align: center;
		box-sizing: border-box;
	}

	#order-details .select-dropdown .status-done {
		pointer-events: none;
		color: #adadad;
		cursor: not-allowed;
	}

		#order-details .select-dropdown .status-done:before {
			content: "";
			display: inline-block;
			mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
			-webkit-mask: url("../../../assets/images/checkbox_checked.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			mask-size: 100%;
			background: #11a732;
			width: 10px;
			height: 10px;
			margin: 0px 10px 0px 0px;
		}

#order-details button.rounded-button.primary.primary-icon.gradient.orange.create-button.invoice {
	height: 29px;
	line-height: 29px;
	padding: 0px 15px;
}

#order-details button.rounded-button.primary.primary-icon.gradient.orange.create-button.invoice:before {
	content: "none";
	display: none;
}

#order-details .content-block-intro {
	justify-content: space-between;
}

.invoice-container {
	background: red;
}